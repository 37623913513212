import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AdfiGraphqlService } from "@services/adfi-graphql.service";
import { AdfiGrowlService } from "@services/adfi-growl.service";
import { AdfiService } from "@services/adfi-service";
import { LoadingService } from "@services/loading.service";
import {Entities, Permission} from '@Interfaces/index';
import {AdfiUtil} from '@Components/util/adfi-util';


export enum BudgetType {
    Income = "I",
    Bill = "E",
}

@Component({
    selector: "app-budget-table",
    templateUrl: "./budget-table.component.html",
    styleUrls: ["./budget-table.component.scss"],
})
export class BudgetTableComponent {
    public loading = false;
    public displayedColumns: string[] = [
        "budget-item",
        "budget-name",
        "budget-value",
    ];
    public filters = [];
    public parameters = ["001", "002", "003", "004", "005"];
    public helps = [];
    public income = [];
    public bill = [];
    public isEditing = false;
    public currentEditElement: object;
    public valor: number;
    public edit: boolean;


    @Input() value: number;
    @Output() changeItem = new EventEmitter<any>();
    @Input() externalPermission = true;


    constructor(
        private service: AdfiService,
        public graphqlService: AdfiGraphqlService,
        public sLoading: LoadingService,
        public alert: AdfiGrowlService
    ) {
        this.edit = !this.service.validPermission(Entities.INITIAL_BUDGET, Permission.APPROVE);
    }

    async update(budgetType: any, source: any) {
        const next = (response) => {
            if (budgetType === BudgetType.Income) {
                this.income.push(...response.flat());
                this.income = this.income.map(element => ({
                    ...element,
                    descriptionHelp: this.findHelpDescription(element.minipresu)
                  }));
            } else if (budgetType === BudgetType.Bill) {
                this.bill.push(...response.flat());
                this.bill = this.bill.map(element => ({
                    ...element,
                    descriptionHelp: this.findHelpDescription(element.minipresu)
                  }));
            }
            this.loading = false;
        };
        const error = (e) => {
            this.loading = false;
            this.alert.errorMessage(e);
        };
        this.loading = true;
        this.sLoading.show();
        const filters = `tipoPresu: "${budgetType}", prminNFuente: "${source}" , vigencia: ${this.value}`;
        const max = await this.graphqlService.countEntity(
            "prMiniPresupuestos",
            filters
        );
        this.graphqlService.getEntity(
            "prMiniPresupuestos",
            "nombre prminNFuente { id prfinVNombre } minipresu descompuesto agrupador valor estadoPresupuesto",
            max,
            filters,
            { next, error }
        );
    }

    public async reloadData() {
        this.income = [];
        this.bill = [];
        this.service.getMainItemsBudget(this.value, this.service.user.centroCosto._id).subscribe(
            async (data) => {
                if(Array.isArray(data.response)) {
                    for (const item of data.response) {
                        if(item.TYPE === BudgetType.Income){
                            this.income.push(item);
                        }else if(item.TYPE === BudgetType.Bill){
                            this.bill.push(item);
                        }
                    }
                    
                }
                if (this.filters.length === 0) {
                    const next = async (d) => {
                        if (Array.isArray(d)) {
                            for (const source of d) {
                                await this.update(BudgetType.Income, source.identifier);
                                await this.update(BudgetType.Bill, source.identifier);
                            }
                            this.sLoading.hide();
                        } else {
                            this.sLoading.hide();
                        }
                    };
                    const error = (e) => {
                        this.sLoading.hide();
                        this.alert.errorMessage(e);
                    };
                    this.graphqlService.getEntity(
                        "prFuentesFinans",
                        "identifier",
                        5,
                        null,
                        { next, error }
                    );
                } else {
                    for (const source of this.filters) {
                        await this.update(BudgetType.Income, +source);
                        await this.update(BudgetType.Bill, +source);
                    }
                    this.sLoading.hide();
                }
            },
            (error) => {
                console.warn("Problema al cargar los datos");
            }
        );
 
        this.getHelps();
        
    }

    editElement(element: any) {
        this.isEditing = true;
        this.currentEditElement = element;
    }
    cancelEdit() {
        this.isEditing = false;
        this.currentEditElement = null;
    }

    save(prMini: any) {
        this.sLoading.show();
        prMini.valor = parseFloat(parseFloat(prMini.valor).toFixed(2));
        const next = () => {
            this.sLoading.hide();
            this.alert.success("Correcto", "Rubro correctamente guardado");
            this.reloadData();
            this.changeItem.emit(true); 
        };

        const error = (e) => {
            this.sLoading.hide();
            this.alert.errorMessage(e);
        };
        this.graphqlService.updateEntity(
            "PrMiniPresupuesto",
            {
                id: prMini.id,
                valor: prMini.valor, 
            },
            undefined,
            { next, error }
        );
    }

    exportTo(format: string) {
            this.service.download(
                'PrMiniPresupuesto',
                '',
                'fileName',
                `application/${format}`,'Ejecucion');
      }

    getClass(status: string, type: string): string {
        if (status != "N" && status != "V") {
            if (status === undefined && type === BudgetType.Income) {
                return "header-item-income";
            }else  if (status === undefined && type === BudgetType.Bill) {
                return "header-item-bill";
            }
            if (type === BudgetType.Income){
                return "header-rows-income";
            }else if (type === BudgetType.Bill) {
                return "header-rows-bill";
            }
        }
        return null;
    }

    getHelps() {
        this.service.getHelps(this.value).subscribe(
            (data) => {
                this.helps= data.response;
            },
            (error) => {
                console.warn("Problema al cargar los datos");
            }
        );
    }

    findHelpDescription(minipresu: string) {
        const matchedItem = this.helps.find(item => item.V_MINIPRESU === minipresu);
        return matchedItem ? matchedItem.DESCRIPTION : '';
    }
}
