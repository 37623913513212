import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {DataEntity, ENUM_ACTIONS, TipoPresupuesto} from '@Interfaces/index';
import {TreePresupuestoComponent} from '@Components/presupuesto/tree-presupuesto/tree-presupuesto.component';
import {AdfiService} from '@services/adfi-service';
import {AdfiGraphqlService} from '@services/adfi-graphql.service';
import {LoadingService} from '@services/loading.service';
import {AdfiGrowlService} from '@services/adfi-growl.service';
import {AdfiUtil} from '@Components/util/adfi-util';
import { UIHelper } from '@Components/util/UIHelper';
import { DisplayItemComponent } from '@Components/ui/display-item/display-item.component';


@Component({
  selector: 'app-previous-initial-budget',
  templateUrl: './previous-initial-budget.component.html',
  styleUrls: ['./previous-initial-budget.component.scss']
})
export class PreviousInitialBudgetComponent implements OnInit {

  /**
     * Fuentes  of prmini presupuesto component
     */
  public fuentes: any[];

  /**
   * Presupuesto inicial of prmini presupuesto component
   */
  public presupuestoInicial: any;

  /**
   * Max height of prmini presupuesto component
   */
  public readonly maxHeight: number;

  /**
   * View child of prmini presupuesto component
   */
  @ViewChildren(TreePresupuestoComponent) presupuestos: QueryList<TreePresupuestoComponent>;

  public archivo: boolean;

  /**
   * Estado  of prmini presupuesto component
   */
  public estado: string;

  /**
   * Determines whether select pr fuente on
   */
  private onSelectPrFuente: boolean;

  period: number;

  constructor(
      public sLoading: LoadingService,
      private matDialog: MatDialog,
      private adfiGrowlService: AdfiGrowlService,
      private adfiService: AdfiService,
      private graphService: AdfiGraphqlService) {
      this.maxHeight = window.innerHeight - 240;
  }

  /**
   * Gets tipo
   */
  public get tipo() {
      return TipoPresupuesto;
  }

  /**
   * on init
   */
  ngOnInit() {
    this.getTime();
  }

  reloadData() {
      this.sLoading.show();
      const next = (d) => {
          if (Array.isArray(d)) {
              this.fuentes = d;
          } else {
              this.sLoading.hide();
              this.presupuestoInicial = d ? d : {};
              this.updatePresupuesto();
          }
      };
      const error = (e) => {
          this.sLoading.hide();
          this.adfiGrowlService.errorMessage(e);
      };
      this.graphService.getEntity('prFuentesFinans', 'prfinVNombre', 5, null, {next, error});
      this.loadMiniPresu(next, error);
  }

  reloadBudget(reload: boolean) {
      const next = (d) => {
              this.presupuestoInicial = d ? d : {};
              this.updateObjectPresupuesto(this.presupuestoInicial);
      };
      const error = (e) => {
          this.adfiGrowlService.errorMessage(e);
      };
      this.loadMiniPresu(next, error);
  }

  getTime() {
    const periodUSer = this.adfiService.getUserPeriod();
    if (periodUSer.estado === 'A') {
        this.period = periodUSer.year;
    }
    this.reloadData();
  }

  loadMiniPresu(next: (d) => void, error: (e) => void) {
      this.graphService.getFirstEntity('presupuestoInicials',
          'fechaAcuerdo numeroAcuerdo estado archivo fileName',
          `centroCosto_id: ${this.adfiService.user.centroCosto._id}, vigencia: "${this.period}"`, {next, error});
  }


  updateObjectPresupuesto(val?: any) {
      if (val) {
          this.presupuestoInicial = Object.assign(this.presupuestoInicial, val);
      }

      if (!this.presupuestoInicial) {
          return;
      }

      switch (this.presupuestoInicial.estado) {
          case 'A':
              this.estado = 'Aprobado';
              break;
          case 'P':
              this.estado = 'Pendiente aprobación';
              break;
          case 'C':
              this.estado = 'Cargado';
              break;
          case 'X':
              this.estado = 'Cerrado';
              break;
          default:
              this.estado = 'Cargado';
              break;
      }
  }

  private updatePresupuesto(val?: any) {
      this.updateObjectPresupuesto(val);
      if (this.onSelectPrFuente) {
          for (const c of this.presupuestos.toArray()) {
              c.update();
          }
      }
  }

  public selectPrFuente(id: number) {
      for (const c of this.presupuestos.toArray()) {
          c.selectPrFuente(id);
      }
      this.onSelectPrFuente = true;
  }

  public descargarArchivo() {
      AdfiUtil.downloadMediaObject(this.presupuestoInicial.archivo, { fieldName: '', options: {bucket: 'files_initial_budget'}},
          this.period);
  }

  getObservations() {
      const next = (d) => {
          if (d!== undefined) {
            const count = d.validationFormHistories.edges.reduce((counter) => {
                return counter+=1;
              }, 0); 
            if (count > 0){
              const mostRecentHistory = d.validationFormHistories.edges.reduce(
                  (mostRecent, current) => {
                    const currentDate = Date.parse(current.node.creationSysDate);
                    const mostRecentDate = Date.parse(mostRecent.node.creationSysDate);
                    return currentDate > mostRecentDate ? current : mostRecent;
                  }
                );
              const next = (x) => {
                  this.sLoading.hide();
                  UIHelper.openDialog(DisplayItemComponent, this.matDialog, {
                      entityName: "ValidationFormHistory",
                      module: "Presupuesto",
                      group: undefined
                  } as DataEntity, ENUM_ACTIONS.VIEW, x.id);
              };
              const error = (e) => {
                  this.sLoading.hide();
                  this.adfiGrowlService.errorMessage(e);
              };
              this.graphService.getFirstEntity('validationFormHistories',
                  'validDealObs',
                  `id: ${mostRecentHistory.node._id}`, {next, error});    
            }else{
                this.sLoading.hide();
                this.adfiGrowlService.warning("Alerta","Todavía no hay observaciones que mostrar");
            }
          }else{
              this.sLoading.hide();
              this.adfiGrowlService.warning("Alerta","Todavía no hay observaciones que mostrar");
          }
          
          
      };
      const error = (e) => {
          this.sLoading.hide();
          this.adfiGrowlService.errorMessage(e);
      };
      this.sLoading.show();
      this.graphService.getFirstEntity('validationForms',
          'validationFormHistories {edges {node {  id  _id  creationSysDate  statusForm  observation  __typename}__typename  }  __typename}',
          `cencosFilter_centroCosto: "${this.adfiService.user.centroCosto._id}" , vigencia: ${this.period}`, {next, error});
  }

}
