<mat-sidenav-container class="sidenav-container">
    <mat-sidenav #drawer mode="over" [autoFocus]="false">
        <div class="nav-header">
            <div class="sidenav-logo-container">
                <img src="assets/images/adfib.png" alt="">
            </div>
            <div class="data-user">
                <div class="row justify-content-md-center">
                    <div class="col-4 username">Usuario:</div>
                    <div class="col-5 username">{{ adfiService.user?.username }}</div>
                </div>
                <div class="row justify-content-md-center">
                    <div class="col-4 name">Nombre:</div>
                    <div class="col-5 name">{{ adfiService.user?.name }} {{ adfiService.user?.apellidos }}</div>
                </div>
                <div class="row justify-content-md-center" *ngIf="adfiService.user.role._id !== 440">
                    <div class="col-4 name">Centro Costo:</div>
                    <div class="col-5 name">
                        <div class="row">
                            {{ adfiService.user?.centroCosto ? adfiService.user.centroCosto?._id : 'Ninguno' }}
                            <button *ngIf="chCencos"
                                    class="icon-cencos"
                                    color="primary" mat-icon-button (click)="changeCencos()"
                                    matTooltip="Cambiar centro de costo">
                                <mat-icon>autorenew</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-md-center">
                    <div class="col-4 name">Vigencia:</div>
                    <div class="col-5 name d-flex justify-content-start">
                        {{ adfiService.user.period }}
                        <mat-form-field class="inline-filter year-select" appearance="none">
                            <mat-select (selectionChange)="changePeriod($event)" [value]="adfiService.user.period">
                                <mat-option
                                        *ngFor="let item of adfiService.periods"
                                        [value]="item.year">{{ item.year }}</mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>
                </div>

                <div class="row justify-content-md-center"  *ngIf="chRole">
                    <div class="col-4 name d-flex">Rol:</div>
                    <div class="col-5 name d-flex">
                        {{ adfiService.user.role.nameProfile }}
                        <mat-form-field class="inline-filter year-select" appearance="none">
                            <mat-select (selectionChange)="changeRole($event)" [value]="adfiService.user.role._id">
                                <mat-option
                                        *ngFor="let item of adfiService.user.rolList"
                                        [value]="item._id">{{ item.nameProfile }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <mat-nav-list>
            <app-menu [items]="menus" (clickedItem)="drawer.toggle()"></app-menu>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content id="top-bar-adfi">

        <mat-toolbar color="primary" class="mat-elevation-z6">
            <button mat-icon-button (click)="drawer.toggle()">
                <mat-icon>menu</mat-icon>
            </button>
            <div class="col-auto" *ngIf="adfiService.user.role._id !== 440">{{ adfiService.user?.centroCosto?.deDescripcion }} <span style="font-size: small;">- {{adfiService.user?.period}}</span></div>
            <div class="col-auto" *ngIf="adfiService.user.role._id == 440">Vigencia: {{adfiService.user?.period}}</div>
            <!-- <a mat-icon-button [routerLink]="previousUrl" *ngIf="activePage?.isChild">
                    <mat-icon>arrow_back</mat-icon>
                </a> -->
            <!-- <div fxFlex="100%">{{getActivePage()?.title}}</div> -->
            <div fxFlex="100%"></div>
            <a [routerLink]="['reviewer-statistics']" mat-icon-button *ngIf="adfiService.user?.role?._id==283 || adfiService.user?.role?._id==440 || adfiService.user?.role?._id==380 || adfiService.user?.role?._id==448" matTooltip="Dashboard" 
                style="margin-right: 6px;">
                <mat-icon> insert_chart_outlined</mat-icon>
            </a>
            <div style="position: relative; top: -5px;">
            <app-adfi-growl></app-adfi-growl>
            </div>
            
            <button mat-icon-button (click)="logout()" matTooltip="Cerrar sesión" style="left: 0;">
                <mat-icon>logout</mat-icon>
            </button>
        </mat-toolbar>

        <div class="container" style="min-height: 60vh">
            <mat-toolbar *ngIf="breadcrumbList.length>1">
                <!-- <button *ngIf="navRouteService.pageBack" mat-raised-button color="primary" matTooltip="Volver"
                    (click)="navRouteService.navPageBack()">
                    <mat-icon>arrow_back</mat-icon>
                    {{ navRouteService.pageBack.nav.title }}
                </button> -->

                <ol class="breadcrumb">
                    <li class="d-flex align-items-center px-2" *ngFor="let item of breadcrumbList; let i = index" [class.active]="i===breadcrumbList.length-1">
                        <a [routerLink]="item.path" *ngIf="i!==breadcrumbList.length-1">
                            <mat-icon class="no-select"> {{ item.icon ? item.icon.toString() : 'navigate_next' }} </mat-icon>
                            <ng-container *ngIf="item.title">
                                {{ item.title.toString() | titlecase}}
                            </ng-container>
                        </a>
                        <span *ngIf="item.title && i==breadcrumbList.length-1">
                            <mat-icon class="mr-1 no-select"> {{ item.icon ? item.icon.toString() : 'navigate_next' }} </mat-icon>
                            {{ item.title.toString() | titlecase }}
                        </span>
                    </li>
                </ol>
            </mat-toolbar>

            <router-outlet></router-outlet>
            <!--footer class="footer-container">
                <div class="footer"></div>
            </footer-->
        </div>

    </mat-sidenav-content>
</mat-sidenav-container>
<app-help-desk *ngIf="adfiService.user && adfiService.user.helpDeskTel"></app-help-desk>
