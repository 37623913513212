import {Route} from '@angular/router';
import {MovimientoBienesRoutes, RoutesPresupuesto} from '@pages/index';
import {environment} from 'src/environments/environment';

import {RoutesAdjustment} from '@pages/adjustment/adjustment-routing.module';
import {ReportsRoutes} from '@pages/reports/reports-routing.module';
import {AdfiRolRouteControlGuard} from '@services/adfi-rol-route-control.guard';
import {Entities, Pages, PeriodState, Permission} from '@Interfaces/index';
import {RoutesDirectorio} from '@pages/directorio/directorio-routing.module';
import {RoutesUser} from '@pages/user/user-routing.module';
import {RoutesClosePeriod} from '@pages/cierre-vigencia/cierre-vigencia-routing.module';
import {BankConciliationRoutes} from '@pages/bank-conciliation/bank-conciliation-routing.module';
import {HelpRoutingModule, RoutesHelp} from "@pages/help/help-routing.module";
import {RoutesTrainings} from '@pages/trainings/trainings-routing.module';
import { AdfibGuard } from '../public/adfib.guard';

/**
 * Nav route
 */
export interface NavRoute extends Route {
  path?: string;
  nav?: RouteData;
  entity?: string;
  permission?: string;
  breadCrumb?: string;
  enabled?: boolean;
  breadcrumbs?: string[];
  groupedNavRoutes?: NavRoute[]|any[];
  periodSate?: PeriodState;
  period?: string;
}

/**
 * Route data
 */
export interface RouteData {
  title?: string;
  icon?: string;
  childrens?: NavRoute[];
}

/**
 * Gets paths
 * @returns paths
 */
export function getPaths(nav: NavRoute[]): NavRoute[] {
  return nav.map((r) => {
    return { ...r, children: [], component: null };
  });
}

export const MenuRoutes: NavRoute[] = [
    {
        path: '',
        redirectTo: Pages.HOME,
        pathMatch: 'full',
    },
    {
        path: Pages.HOME,
        nav: { title: 'Inicio', icon: 'home' },
        loadChildren: () => import('@pages/home-page/home-page.module').then(m => m.HomePageModule),
        canActivate: [AdfibGuard]
    },
    {
        path: Pages.BUDGET,
        nav: {  title: 'Presupuesto', icon: 'monetization_on',
            childrens: environment.production ? RoutesPresupuesto : getPaths(RoutesPresupuesto) },
        loadChildren: () => import('@pages/presupuesto/presupuesto.module').then(m => m.PresupuestoModule),
        canActivateChild: [AdfiRolRouteControlGuard]
    },
    {
        path: Pages.ACCOUNTING,
        nav: {  title: 'Contabilidad', icon: 'calculate', childrens: environment.production ? RoutesAdjustment : getPaths(RoutesAdjustment) },
        loadChildren: () => import('@pages/adjustment/adjustment.module').then(m => m.AdjustmentModule),
        canActivateChild: [AdfiRolRouteControlGuard]
    },
    {
        path: Pages.MOVEMENT_ASSETS,
        nav: {  title: 'Propiedad Planta y Equipo', icon: 'compare_arrows',
            childrens: environment.production ? MovimientoBienesRoutes : getPaths(MovimientoBienesRoutes) },
        loadChildren: () => import('@pages/movimiento-bienes/movimiento-bienes.module').then(m => m.MovimientoBienesModule),
        canActivateChild: [AdfiRolRouteControlGuard]
    },
    {
        path: Pages.REPORTS,
        nav: {
            title: 'Reportes Generales',
            icon: 'input',
            childrens: environment.production
                ? ReportsRoutes
                : getPaths(ReportsRoutes),
        },
        loadChildren: () => import('@pages/reports/reports.module').then((m) => m.ReportsModule),
        canActivateChild: [AdfiRolRouteControlGuard]
    },
    {
        path: Pages.DIRECTORY,
        nav: {  title: 'Directorio Activo', icon: 'people',
            childrens: environment.production ? RoutesDirectorio : getPaths(RoutesDirectorio) },
        loadChildren: () => import('@pages/directorio/directorio.module').then(m => m.DirectorioModule),
        canActivateChild: [AdfiRolRouteControlGuard]
    },
    {
        path: Pages.ACCOUNTING,
        nav: {  title: 'Conciliación Bancaria', icon: 'account_balance',
            childrens: environment.production ? BankConciliationRoutes : getPaths(BankConciliationRoutes) },
        loadChildren: () => import('@pages/bank-conciliation/bank-conciliation.module')
            .then(m => m.BankConciliationModule), // menu-temp
        canActivateChild: [AdfiRolRouteControlGuard]
    },
    {
        path: Pages.CLOSE_PERIOD,
        nav: {  title: 'Cierre de Vigencia', icon: 'fact_check', childrens: environment.production ? RoutesClosePeriod : getPaths(RoutesClosePeriod) },
        loadChildren: () => import('@pages/cierre-vigencia/cierre-vigencia.module').then(m => m.CierreVigenciaModule),
        canActivateChild: [AdfiRolRouteControlGuard],
        periodSate: PeriodState.CLOSE
    },
    {
        path: Pages.ADFI_FORM,
        nav: {  title: 'AdfiForm', icon: 'format_align_justify'},
        loadChildren: () => import('@pages/adfi-form/adfi-form.module').then(m => m.AdfiFormModule),
        entity: Entities.ADFI_FORM,
        canActivate: [AdfiRolRouteControlGuard]
    },
    {
        path: Pages.TRAINING,
        nav: {  title: 'Capacitación', icon: 'emoji_people',
            childrens: environment.production ? RoutesTrainings : getPaths(RoutesTrainings)},
        loadChildren: () => import('@pages/trainings/trainings.module').then(m => m.TrainingsModule),
        canActivateChild: [AdfiRolRouteControlGuard]
    },
    {
        path: Pages.USER,
        nav: {  title: 'Mi Perfil', icon: 'account_circle', childrens: environment.production ? RoutesUser : getPaths(RoutesUser) },
        loadChildren: () => import('@pages/user/user.module').then(m => m.UserModule),
        entity: Entities.EMPLOYEE,
        permission: Permission.CHANGE_MY_PASSWORD,
        periodSate: PeriodState.NO_STATUS,
        canActivate: [AdfiRolRouteControlGuard]
    },
    {
        path: Pages.GENERIC_LIST,
        nav: {  title: 'Listar', icon: 'sort'},
        loadChildren: () => import('@pages/generic/generic.module').then(m => m.GenericModule)
    },
    {
        path: Pages.HELP,
        loadChildren: () => import('@pages/help/manuals.module').then(m => m.ManualsModule),
        entity: Entities.MANUALS
    },
    {
        path: Pages.REVIEWER_STATISTICS,
        nav: {  title: 'Estadísticas', icon: 'insert_chart_outlined'},
        loadChildren: () => import('@pages/reviewer-statistics/reviewer-statistics.module').then(m => m.ReviewerStatisticsModule),
        canActivate: [AdfibGuard]
    }
];
