import {Component, OnInit, ViewChild} from '@angular/core';
import {ListComponent} from '@Components/ui/list/list.component';
import {LoadingService} from '@services/loading.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AdfiGrowlService} from '@services/adfi-growl.service';
import {AdfiService} from '@services/adfi-service';
import {ActivatedRoute} from '@angular/router';
import {AdfiGraphqlService} from '@services/adfi-graphql.service';
import {DataEntity, Entities, ENUM_ACTIONS, PeriodState, Permission} from '@Interfaces/index';
import {FormComponent} from '@Components/ui/form/form.component';
import {ConfirmDialogComponent, ConfirmDialogData} from '@Components/ui/confirm-dialog/confirm-dialog.component';
import {Moment} from 'moment/moment';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
type SiNo = 'S' | 'N' | 'D';

@Component({
  selector: 'app-conciliation-seb',
  templateUrl: './conciliation-seb.component.html',
  styleUrls: ['./conciliation-seb.component.scss']
})
export class ConciliationSebComponent implements OnInit {

  @ViewChild('moveTable') moveTable: ListComponent;
  @ViewChild('part') partTable: ListComponent;

  canStore = false;
  canRemove = false;
  canApprove = false;
  canUpdate = false;

  bankAccount: any;
  conciliation: any;
  currentPeriod: number;
  periods: any[];
  allConciliation: any[];
  bankAccounts: any[];

  public readonly data: {
    sumMovements: number;
    sumParts: number;
  };

  constructor(
      public loading: LoadingService,
      private matDialog: MatDialog,
      private adfiGrowlService: AdfiGrowlService,
      private adfibService: AdfiService,
      private route: ActivatedRoute,
      private graphqlService: AdfiGraphqlService,
      private gaService: GoogleAnalyticsService
  ) {
    this.data = {sumMovements: 0, sumParts: 0};
  }

  async ngOnInit() {
    this.canStore = this.adfibService.validPermission(Entities.LOT_CONCILIATION, Permission.ADD, PeriodState.NO_STATUS);
    this.canRemove = this.adfibService.validPermission(Entities.LOT_CONCILIATION, Permission.DELETE, PeriodState.NO_STATUS);
    this.canApprove = this.adfibService.validPermission(Entities.LOT_CONCILIATION, Permission.APPROVE, PeriodState.NO_STATUS);
    this.canUpdate = this.adfibService.validPermission(Entities.LOT_CONCILIATION, Permission.EDIT, PeriodState.NO_STATUS);

    this.loading.show();
    const next = (data) => {
      this.bankAccounts = data;
      if (this.periods) {
        this.loading.hide();
      }
    };
    const error = (e) => {
      this.adfiGrowlService.errorMessage(e);
      if (this.periods) {
        this.loading.hide();
      }
    };
    const filter = 'cencosFilter_centroCosto: ""';
    const all = await this.graphqlService.countEntity('cuentaBancos', filter);
    this.graphqlService.getEntity('cuentaBancos', 'nombre,numero,vMaint', all, filter, {next, error});
    this.periods = this.adfibService.periods;
    this.currentPeriod = this.periods && this.periods[0] ? this.periods[0].year : undefined;
  }

  async selectAllConciliation(account: any) {
    if (account) {
      this.loading.show();
      this.bankAccount = account;
      this.conciliation = undefined;
      const next = (data) => {
        this.allConciliation = data;
        this.loading.hide();
      };
      const error = () => {
        this.loading.hide();
      };
      const after = new Date(this.currentPeriod, 0, 1);
      const before = new Date(this.currentPeriod, 11, 31, 23, 59, 59);
      const filter = `cuentaBancaria: "${this.bankAccount.id}",fechaInicial: { after: "${after.toUTCString()}", before: "${before.toUTCString()}" }`;
      const all = await this.graphqlService.countEntity('loteConciliacions', filter);
      this.graphqlService.getEntity('loteConciliacions',
          'fechaInicial,fechaFinal,saldoExtracto,saldoInicial, saldoFinal,diferencia,estado,source',
          all, filter, {next, error});
    }
  }

  public selectConciliation(conciliation: any) {
    this.conciliation = conciliation;
    this.conciliation.diff = (+conciliation.saldoFinal + +conciliation.diferencia).toFixed(2);
    if (this.moveTable) {
      this.moveTable.reload();
    }
    if (this.partTable) {
      this.partTable.reload();
    }
  }

  public newConciliation() {
    const customData: any = {cuentaBancaria: this.bankAccount.id};
    if (this.currentPeriod === 2019) {
      customData.saldoInicial = 0;
    }
    const dialog = this.matDialog.open(FormComponent, {
      data: {
        entityName: 'LoteConciliacion',
        module: 'Tesoreria',
        action: ENUM_ACTIONS.CREATE,
        customData
      } as DataEntity,
      minWidth: '50%'
    });
    let ctrlInitVal;
    let ctrlEndVal;
    dialog.componentInstance.preLoad.subscribe((col) => {
      const control = col.control;
      if (col.column.fieldName === 'fechaInicial') {
        control.assignedNatives.subscribe(() => {
          control.picker.min = new Date(this.currentPeriod, 0, 1);
          control.picker.max = new Date(this.currentPeriod, 11, 1);
        });
        control.valueChanges.subscribe((v: Moment) => {
          if (v){
            const next = (ex) => {
              if (ex || ex === 0) {
                ctrlInitVal.setValue(ex.value);
              } else {
                this.adfiGrowlService.warning('Alerta',
                    'Aún NO se ha registrado el extracto de la cuenta seleccionada para extraer el saldo inicial', 4500);
                ctrlInitVal.setValue(null);
              }
            };
            const error = () => {
              ctrlInitVal.setValue(null);
            };
            const paramDate = v.clone().subtract(1, 'months');
            const params = `period: ${paramDate.year()}, month: ${paramDate.month() + 1}, account: "${this.bankAccount.id}"`;
            this.graphqlService.getFirstEntity('extractBankByAccounts', 'value', params, {next, error});
          } else {
            ctrlInitVal.setValue(null);
          }
        });
      } else if (col.column.fieldName === 'fechaFinal') {
        control.assignedNatives.subscribe(() => {
          control.picker.max = new Date(this.currentPeriod + 1, 0, 0);
        });
        control.valueChanges.subscribe((v: Moment) => {
          if (v) {
            const next = (ex) => {
              if (ex || ex === 0) {
                ctrlEndVal.setValue(ex.value);
              } else {
                this.adfiGrowlService.warning('Alerta',
                    'Aún NO se ha registrado el extracto de la cuenta seleccionada para extraer el saldo final', 4500);
                ctrlEndVal.setValue(null);
              }
            };
            const error = () => {
              ctrlEndVal.setValue(null);
            };
            const filter = `period: ${v.year()}, month: ${v.month() + 1}, account: "${this.bankAccount.id}"`;
            this.graphqlService.getFirstEntity('extractBankByAccounts', 'value', filter, {next, error});
          } else {
            ctrlEndVal.setValue(null);
          }
        });
      } else if (col.column.fieldName === 'saldoInicial') {
        ctrlInitVal = control;
      } else if (col.column.fieldName === 'saldoFinal') {
        ctrlEndVal = control;
      }
    });
    dialog.beforeClosed().toPromise().then( async (e) => {
      if (e) {
        await this.selectAllConciliation(this.bankAccount);
      }
    });
  }

  getExtractBankByAccounts(period: number, month: number, account: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.graphqlService.getFirstEntity(
        'extractBankByAccounts',
        'value',
        `period: ${period}, month: ${month}, account: "${account}"`,
        {
          next: (ex) => {
            if (ex || ex === 0) {
              resolve(ex.value);
            } else {
              this.adfiGrowlService.warning('Alerta', 'Aún NO se ha registrado el extracto de la cuenta seleccionada para extraer el saldo inicial', 4500);
              resolve(null);
            }
          },
          error: () => {
            resolve(null);
          }
        }
      );
    });
  }

  async updateConciliation() {
    let ctrlInitVal;
    let ctrlEndVal;
    let monthInicial = new Date(this.conciliation.fechaInicial);
    ctrlInitVal= await this.getExtractBankByAccounts(monthInicial.getFullYear(), monthInicial.getMonth(), this.bankAccount.id);
    let monthFinal = new Date(this.conciliation.fechaFinal);
    ctrlEndVal= await this.getExtractBankByAccounts(monthFinal.getFullYear(), monthFinal.getMonth()+1, this.bankAccount.id);
    const next = (response) => {
      this.conciliation.diff = (+response.saldoFinal + +response.diferencia).toFixed(2);
      this.conciliation.saldoInicial = response.saldoInicial;
      this.conciliation.saldoFinal = response.saldoFinal;
      this.conciliation.diferencia = response.diferencia;
      this.loading.hide();
      this.adfiGrowlService.success('Movimiento actualizado correctamente', '');
    };
    const error = (e) => {
      this.adfiGrowlService.errorMessage(e);
      this.loading.hide();
    };
  this.graphqlService.updateEntity('LoteConciliacion', {
    id: this.conciliation.id,
    saldoInicial: ctrlInitVal,
    saldoFinal: ctrlEndVal
  }, 'saldoInicial saldoFinal diferencia', {next, error});
  }

  public removeConciliation() {
    const dialog = this.matDialog.open(ConfirmDialogComponent, {data: {
        title: 'Eliminar',
        content: `¿Esta seguro de eliminar la conciliación?`,
        action: ENUM_ACTIONS.DELETE
      } as ConfirmDialogData });
    dialog.beforeClosed().subscribe((ok) => {
      if (ok) {
        this.loading.show();
        const next = async () => {
          this.adfiGrowlService.info('Eliminado', 'Conciliación eliminada correctamente');
          this.loading.hide();
          await this.selectAllConciliation(this.bankAccount);
        };
        const error = (e) => {
          this.adfiGrowlService.errorMessage(e);
          this.loading.hide();
        };
        this.graphqlService.deleteEntity('LoteConciliacion', this.conciliation.id, {next, error});
      }
    });
  }

  public approveConciliation() {
    const dialog = this.matDialog.open(FormComponent, {
      data: {
        entityName: 'LoteConciliacion',
        module: 'Tesoreria',
        group: 'approve',
        item: this.conciliation.id,
        customTitle: 'Aprobar Conciliación',
        customActionBtnText: 'Aprobar',
        action: ENUM_ACTIONS.EDIT
      } as DataEntity,
      minWidth: '50%'
    });
    dialog.afterClosed().subscribe(async (ok) => {
      if (ok) {
        await this.selectAllConciliation(this.bankAccount);
      }
    });
  }

  /*documentSupport() {
      this.matDialog.open(FormComponent, {
          data: {
              entityName: 'LoteConciliacion',
              module: 'Tesoreria',
              group: 'upload',
              item: this.conciliation.id,
              customTitle: 'Subir Extracto Bancario',
              customActionBtnText: 'Subir',
              action: ENUM_ACTIONS.EDIT
          } as DataEntity,
          minWidth: '50%'
      });
  }

  downloadFile() {
      AdfiUtil.downloadMediaObject(this.conciliation, {fieldName: 'documentoSoporte', options: { bucket : 'files_bank_extract'}});
  }*/

  public changeMovements(val: any[], moveTable: ListComponent) {
    if (val && val.length > 0) {
      this.sumValues('MovimientoBancario', moveTable.getAllFilters());
    }
    this.data.sumMovements = 0;
  }

  public changeParts(val: any[], table: ListComponent) {
    if (val && val.length > 0) {
      this.sumValues('PartidaConciliacion', table.getAllFilters());
    }
    this.data.sumParts = 0;
    if (this.partTable.dataEntity) {
      this.partTable.dataEntity.enableAdd = this.conciliation.estado !== 'A';
    } else {
      this.partTable.dataEntity = {} as DataEntity;
      this.partTable.dataEntity.enableAdd = this.conciliation.estado !== 'A';
    }
  }

  private sumValues(nameClass: 'MovimientoBancario' | 'PartidaConciliacion', filters: any) {
    const next = (d) => {
      if (d) {
        if (nameClass === 'MovimientoBancario') {
          this.data.sumMovements = d.total;
        } else {
          this.data.sumParts = d.total;
        }
      }
    };
    const error = (e) => {
      this.adfiGrowlService.errorMessage(e);
    };
    this.graphqlService.createEntity('Summation', {
      nameClass: 'Tesoreria\\' + nameClass,
      filters: JSON.stringify(filters)
    }, 'total', {next, error});
  }

  public exportConciliation(all) {
    this.adfibService.download(
        'Tesoreria\\LoteConciliacion',
        JSON.stringify({id: this.conciliation._id, all}),
        'fileName',
        'application/pdf',
        'ReporteConciliaciones.pdf');
  }

  public exportXlsx(className: string, fileName: string, moveTable: ListComponent) {
    this.adfibService.download(
        className,
        JSON.stringify(moveTable.getAllFilters()),
        'fileName',
        'application/xlsx',
        fileName);
  }

  public conciliarMovements(value: SiNo) {
    this.loading.show();
    this.gaService.event('Conciliar TODOS' , 'ADFI', value === 'S' ? 'Conciliados' : 'Cancelados',  this.adfibService.user.id);
    const next = (d) => {
      if (d) {
        this.moveTable.reload();
        this.updateValuesConciliation();
        this.adfiGrowlService.success(this.moveTable.configTable.name, `${value === 'S' ? 'Conciliados' : 'Cancelados'} movimientos del Sisema`);
      } else {
        this.adfiGrowlService.error(`Error al ${value === 'S' ? 'Aprobar' : 'Cancelar'} Conciliaciones`,
            this.moveTable.configTable.name);
      }
      this.loading.hide();
    };
    const error = (e) => {
      this.adfiGrowlService.errorMessage(e);
      this.loading.hide();
    };
    this.graphqlService.createEntity('Conciliar', {
      nameClass: 'Tesoreria\\MovimientoBancario',
      conciliado: value,
      filters: JSON.stringify(this.moveTable.getAllFilters())
    }, null, {next, error});
  }

  public conciliar(movement: any, value: SiNo) {
    this.loading.show();
    this.gaService.event('Conciliar Movimiento' , 'ADFI', value === 'S' ? 'Conciliado' : 'Cancelado', this.adfibService.user.id);
    const next = (response) => {
      this.loading.hide();
      movement.conciliado = response.conciliado;
      this.adfiGrowlService.success('Movimiento actualizado correctamente', '');
      this.updateValuesConciliation();
    };
    const error = (e) => {
      this.adfiGrowlService.errorMessage(e);
      this.loading.hide();
    };
    this.graphqlService.updateEntity('MovimientoBancario', {
      id: movement.id,
      conciliado: value
    }, 'conciliado', {next, error});
  }

  public cancelPart(part, table) {
    this.gaService.event('Abrir Dialogo' , 'ADFI',  'Cancelar_Partida_Conciliacion', this.adfibService.user.id);
    const dialog = this.matDialog.open(FormComponent, {
      data: {
        entityName: 'PartidaConciliacion',
        module: 'Tesoreria',
        group: 'cancel',
        customData: {conciliado: 'N'},
        item: part.id,
        customTitle: 'Cancelar partida',
        customActionBtnText: 'Cancelar Partida',
        action: ENUM_ACTIONS.EDIT
      } as DataEntity,
      minWidth: '50%'
    });
    dialog.afterClosed().subscribe((d) => {
      if (d) {
        table.reload();
        this.updateValuesConciliation();
      }
    });


  }

  public conciliarPart(partida, value: SiNo) {
    this.loading.show();
    const next = (response) => {
      this.loading.hide();
      partida.conciliado = response.conciliado;
      this.adfiGrowlService.success('Partida actualizada correctamente', '');
      this.updateValuesConciliation();
    };
    const error = (e) => {
      this.adfiGrowlService.errorMessage(e);
      this.loading.hide();
    };
    this.graphqlService.updateEntity('PartidaConciliacion', {
      id: partida.id,
      conciliado: value
    }, 'conciliado', {next, error});
  }

  private updateValuesConciliation() {
    this.loading.show();
    const next = (res) => {
      if (res) {
                this.conciliation.diferencia = res.diferencia;
      }
      this.loading.hide();
    };
    const error = (e) => {
      this.loading.hide();
      this.adfiGrowlService.errorMessage(e);
    };
    this.graphqlService.getOneEntity('loteConciliacion', 'diferencia', this.conciliation.id, undefined, {next, error});
  }

  dialog(dialogRef: MatDialogRef<FormComponent>) {
    dialogRef.componentInstance.data.customData = {cuenta: this.bankAccount.id};
    dialogRef.componentInstance.preLoad.subscribe((col) => {
      if (col.column.fieldName === 'fecha') {
        col.control.assignedNatives.subscribe(() => {
          col.control.picker.min = this.conciliation.fechaInicial;
          col.control.picker.max = this.conciliation.fechaFinal;
          col.control.setValue(this.conciliation.fechaFinal);
        });
      }
    });
  }

}
