
/**
 * Tipo presupuesto
 */
export enum TipoPresupuesto {
    INGRESO = 'I',
    EGRESO = 'E'
}

/**
 * Tipo traslado
 */
export enum TipoTraslado {
    CREDITO = 'CR',
    CONTRACREDITO = 'CC',
}

/**
 * Status
 */
export enum Status {
    CERRADO = 'C',
    ANULADO = 'N'
}

export enum Actions {
    CERRAR_DOCUMENTO = 'cierredocumento'
}

export enum Constants {
    SI = 'S',
    NO = 'N'
}

export enum TipoAcuerdo {
    TIPO_TRASLADO = 'T',
    TIPO_MODIFICACION = 'M'
}

export enum EstadosAcuerdo {
    INICIAL = 'I',
    APROBADO = 'A',
    ANULADO = 'N',
    CARGADO = 'C',
    PENDIENTE = 'P'
}

export enum EstadosAcuerdoTexto {
    I = 'Inicial',
    A = 'Aprobado',
    N = 'Anulado',
    C = 'Cargado',
    P = 'Pendiente de Aprobación'
}

export enum PeriodState {
    INITIAL = 'I',
    OPEN = 'A',
    CLOSE = 'C',
    NO_STATUS = 'S',
}

export interface Roles {
    rector?: boolean;
    auxiliar?: boolean;
    admin?: boolean;
}

export const ROLE_ADMIN = 'ADMIN';
export const ROLE_RECTOR = 'RECTOR';
export const ROLE_AUXILIAR = 'AUXILIAR_';
export const ROLE_CONCILIADOR = 'CONCILIADOR';
export const ROLES = [ROLE_ADMIN, ROLE_AUXILIAR, ROLE_RECTOR, ROLE_CONCILIADOR];
export const ROLE_SUPER_USER = 'superuser';

/**
 * Menu
 */
export interface Menu {
    name: string;
    path?: string;
    icon?: string;
    view?: boolean;
    childrens?: Menu[];
}

export enum Permission {
    LIST = 'list',
    VIEW = 'view',
    ADD = 'add',
    EDIT = 'edit',
    DELETE = 'delete',
    APPROVE = 'approve',
    APPROVE_MANAGER = 'apro_lider',
    APPROVE_ACCOUNT_MAN = 'apro_conta',
    CHANGE_CENCOS = 'ch_cencos',
    CHANGE_VIGENCIA = 'ch_vigencia',
    CHANGE_ROLE = 'ch_role',
    DOWNLOAD = 'download',
    UPLOAD = 'upload',
    VIEW_ALL = 'v_all',
    VIEW_ADMIN = 'v_admin',
    VIEW_ALL_ROLES = 'v_all_role',
    VIEW_INCOME = 'v_ingress',
    VIEW_EXPENSES = 'v_egress',
    TRANSFER = 'transfer',
    DOWN = 'down',
    OPEN = 'open',
    CLOSE = 'close',
    PENDING = 'pending',
    ASSIGN = 'assign',
    CHANGE_PASSWORD = 'ch_pass',
    RESTORE_PASSWORD = 'rest_pass',
    CHANGE_MY_PASSWORD = 'ch_my_pass',
    CHANGE_MY_DOCS = 'ch_my_docs',
    PDF = 'pdf',
    SEE_OBSERVATION ='see_obsrv'
}

export  enum Entities {
    INITIAL_BUDGET = 'PresupuestoInicial',
    BUDGET_EXECUTION = 'BudgetExecution',
    AUXILIARY_BUDGET_BOOKS = 'AuxiliaryBudgetBooks',
    AUXILIARY_CONTAB_BOOKS = 'AuxBooksContab',
    CONCILIATION = 'Conciliacion',
    LOT_CONCILIATION = 'LoteConciliacion',
    ACCOUNT = 'CuentaBanco',
    BANK = 'Banco',
    ADJUSTMENT = 'Adjustment',
    DOCUMENT = 'Document',
    ACTIVO_FIJO = 'ActivoFijo',
    ACTIVE_EMPLOYEE = 'PpeActiveEmployee',
    HELP_DESK = 'HelpDesk',
    REPORT_EXCEL = 'ReportExcel',
    REPORT_COMPTROLLERSHIP = 'ReportComptrollership',
    REPORT_BALANCE = 'ReportBalance',
    ADFI_FORM = 'AdfiForm',
    COST_CENTER = 'PrCentroCosto',
    EMPLOYEE = 'Employee',
    SEDE = 'Sede',
    ACCESS_RIGHT = 'AccessRights',
    ROLES_BY_USER = 'RoleByUser',
    RESERVATION = 'Reservation',
    CLOSING_FORM = 'ClosingForm',
    VALIDATION_FORM = "ValidationForm",
    BALANCE_RESOURCE = 'BalanceResource',
    END_PERIOD_ACCOUNTS = 'AccountBalance',
    REQUEST_RESOURCE= 'BudgetReserve',
    FINANCIAL_STATE  = 'FinancialState',
    TE_MOV_EGRE  = 'TeMovEgre',
    CHECKING_BALANCE = 'CheckingBalance',
    RESERVE = 'Reserve',
    PAYMENT_ORDER_WP = 'PaymentOrderWP',
    MANUALS = 'Manuals',
    VIDEOS = 'Videos',
    CALL_ASSISTANCE = 'CallAssistance',
    ANALITYCS = 'Analitycs',
}

export enum Pages {
    HOME = 'home',
    DIRECTORY = 'directorio',
    DIRECTORY_USER_CENCOS = 'info',
    DIRECTORY_USER = 'users',
    DIRECTORY_SEDES_CENCOS = 'sedes-cencos',
    DIRECTORY_HOME = 'inicio',
    PERMISSIONS = 'permissions-by-role',
    // Presupuesto
    BUDGET = 'presupuesto',
    BUDGET_INITIAL = 'inicial',
    BUDGET_SUMMARY = 'resumen',
    PREVIOUS_SUMMARY = 'previous-summary',
    BUDGET_INCOME = 'ingresos',
    BUDGET_EXPENSES = 'egresos',
    BUDGET_TRANSFER = 'traslado',
    BUDGET_TRANSFERS = 'traslados',
    BUDGET_MODIFICATION = 'modificacion',

    ANNUAL_ACQUISITION_PLAN = 'plan-anual-adquisiciones',
    PREVIOUS_ANNUAL_ACQUISITIONS_PLAN = 'previous-annual-acquisitions-plan',

    // Tesoreria
    TREASURY = 'tesoreria',
    TREASURY_INCOME = 'ingreso',
    TREASURY_PAID_ORDER = 'orden-pago',
    TREASURY_PAID = 'pago',
    // Egresos
    EXPENSES = 'egresos',
    EXPENSES_AVAILABILITY = 'disponibilidad',
    EXPENSES_MASTER_AVAILABILITY = 'maestro-disponibilidad',
    EXPENSES_BUDGET_REGISTER = 'registro-presupuestal',
    EXPENSES_PAID_ORDER = 'orden-pago',
    // Bienes
    MOVEMENT_ASSETS = 'movimiento-bienes',
    MOVEMENT_ASSETS_UP = 'alta-bienes',
    MOVEMENT_ASSETS_DOWN = 'baja-bienes',
    MOVEMENT_CONTROL = 'control-propiedad',
    MOVEMENT_ACCESSORIES = 'accesorios',
    MOVEMENT_ASSETS_FORM_ADD = 'ingreso-bienes',
    // Contabilidad
    ACCOUNTING = 'contabilidad',
    CONSILIATION = 'conciliaciones',
    CONSILIATION_REGISTER = 'registro',
    ACCOUNTING_BEFORE = 'anterior',
    ACCOUNTING_ACCOUNTS = 'cuentas-banco',
    ACCOUNTING_BANK = 'banco',
    ACCOUNTING_CHECKERS = 'comprobantes',
    ACCOUNTING_CHECKERS_ADMIN = 'admin',

    // cgn
    CGN = 'cgn',
    CGN_INITIAL = 'cgn-inicial',
    CGN_PROCESSED_DATA = 'datos-procesados',
    // Parametrización
    PARAMETERIZATION = 'parametrizacion',
    PARAMETERIZATION_PERIOD = 'vigencia',
    // Reportes
    REPORTS = 'reports',
    REPORTS_BUDGET_EXECUTION = 'ejecucion',
    REPORTS_AUX_BUDGET_BOOKS = 'libros',
    REPORTS_CONSOLIDATED_BALANCE_SHEET = 'consolidated-balance-sheet',
    REPORTS_HELP_DESK = 'rep-help-desk',
    REPORTS_INCOME_CONCILIATION = 'ingreso-conciliacion',
    REPORTS_COVID = 'covid',
    REPORTS_COMPTROLLERSHIP = 'reporte-contraloria',
    REPORTS_FINANCIAL_STATE= 'reporte-estado-situacion-financiera',
    REPORTS_TRANSACTIONS_THIRD= 'reporte-operaciones-a-favor-terceros',
    REPORTS_PROFIT_LOSS_STATE= 'reporte-estado-ganancias-perdidas',
    REVIEWER_STATISTICS='reviewer-statistics',
    // Otros...mejor dicho terceros
    OTHERS = 'otros',
    // Actualización general
    GENERAL_UPDATE = 'actualizacion-general',
    // share
    AGREEMENT = 'acuerdo',
    ADFI_FORM = 'training-form',
    USER = 'user',
    CHANGE_PASSWORD = 'change-password',
    CHANGE_DOCUMENTS = 'profile',
    TRAINING_RESERVATION = 'inscripcion',
    TRAINING_CALL_ASSISTANCE = 'llamar-asistencia',
    TRAINING = 'capacitacion',
    // cierre vigencia
    CLOSE_PERIOD = 'cierre-vigencia',
    COMPLEMENTARY_INFO = 'informacion-complementaria',
    VALIDATION_FORM_PATH = "formulario-validacion-pi-paa",
    FORM_BALANCE_ROESOURCES = 'formulario-recursos-balance',
    REQUEST_BALANCE_RESOURCES = 'solicitudes-recursos-balance',
    END_PERIOD_ACCOUNTS = 'cuentas-bancarias/resumen',
    GENERIC_LIST = 'list',
    CHECKING_BALANCE = 'balance-comprobacion',
    SOLICITUDE_RESERVATIONS= 'solicitud-reservas',
    SOLICITUDE_LIST_RESERVATIONS= 'lista-solicitud-reservas',
    BUDGET_RESERVE_LEDGER = 'budget-reserve-ledger',
    ACCOUNT_PAYMENT_LEDGER = 'account-x-payment-ledger',
    MANUALS = 'manuals',
    VIDEOS = 'videos',
    HELP = 'help',
    REPORTS_AUX_BUDGET_BOOKS_CONTAB = 'libros',
    ANALITYCS = 'analitycs',
}

/**
 * Procesar
 */
export interface Procesar {
    start?: Date;
    end?: Date;
}

/**
 * Pr ini cntrl
 */
export interface PrIniCntrl {
    N_YYYY_CLOSE?: string;
    N_YYYY_PR?: string;
}

export enum ValidationType {
    REGEX = 'Regex',
    NOT_BLANK = 'NotBlank',
    NOT_NULL = 'NotNull',
    LENGTH = 'Length',
    RANGE = 'Range',
}

export enum Options {
    TOOLTIP = 'tooltip',
    CELL_CLASS = 'cellClass',
    ACTIONS_EXPAND = 'actionsExpand',
    CELL_EXPAND = 'cellExpand',
    KEY = 'key',
    VALUE_PROPERTY = 'value',
    SAME = 'same',
    NO_SAME = 'noSame',
    ASSIGN = 'assign',
    ACTIONS_TABLE = 'actionsTable',
    CLASS_FORM = 'classform',
    HIDDEN_SYMBOL = 'hiddenSymbol',
    FILE_TYPE = 'fileType',
    FILE_ENTITY = 'fileEntity',
    FILE_ACTION = 'fileAction',
    MULTIPLE_FILE = 'multipleFile',
    FILE_NAME = 'fileName',
    FIELD_NAME = 'fieldName',
    FORCE_QUERY = 'forceQuery',
    FILTER_SELECT = 'filterSelect',
    FILTER = 'filter',
    FILTER_IN_PROP = 'filter_prop',
    BUCKET = 'bucket',
    HIDDEN_ADD = 'hiddenAdd',
    HIDDEN_EDIT = 'hiddenEdit',
    HIDDEN_DISPLAY = 'hiddenDisplay',
    HIDDEN_LIST = 'hiddenList',
    DATA_DISPLAY = 'dataDisplay',
    ENABLED_ADD = 'enabledAdd',
    NULLABLE = 'nullable',
    MIN_DATE = 'minDate',
    MAX_DATE = 'maxDate',
    NO_REQUIRED_VALUE = 'noRequiredValue',
    AUTO_ASSIGN_VALUE= 'autoAssignValue',
    CAN_ADD = 'canAdd',
    ICON = 'icon',
    NOT_ID = 'notId',
    MIN_DATE_BACKEND = 'minDateBackend',
    MAX_DATE_BACKEND = 'maxDateBackend',
    MAT_SELECT = 'matselect',
    DISABLED_EDIT = 'disabledEdit',
    ADD_END_OF_DAY = 'addEndOfDay',
    RELATIVE_FILTER = 'relativeFilter',
    DEFAULT_DATA = 'defaultData',
    CUSTOM_RETURN = 'customReturn',
    BULK_DOWNLOAD = 'bulkDownload',
    FOLDERS = 'folders',
    GROUP_ADD = 'groupAdd',
    GROUPS_EDIT = 'groupsEdit',
    RELATIVE_ENTITY = 'relativeEntity',
    GROUP_LIST = 'group_list',
    JSON_ENCODE = 'jsonEncode',
    DISPLAY_GROUP = 'displayGroup',
    VIRTUAL = 'virtual',
    LABEL_OVER = 'labelOver',
    PLACEHOLDER = 'placeholder',
    ITEMS_PER_PAGE = 'itemsPerPage',
    ROW_CLASS = 'rowClass',
    BUCKET_NO_VIGENCIA = 'bucketNoVigencia',
    BUCKET_CUSTOM_VIGENCIA = 'bucketCustomVigencia',
    BUCKET_NEXT_VIGENCIA = 'bucketNextVigencia',
    VIEW_DETAIL_ITEM = 'viewDetail',
    RELATIVE_GROUP = 'relativeGroup',
    RELATIVE_DEFAULT_FILTERS = 'relativeDefaultFilters',
    CAN_ADD_DETAIL = 'canAddDetail',
    CAN_VIEW_ACTIONS = 'canViewActions',
    CAN_DELETE = 'canDelete',
    CAN_EDIT = 'canEdit',
    CUSTOM_COMPONENT = 'customComponent',
    VIEW_AFTER_ADD = 'viewAfterAdd',
    TITLE_WARNING_SAVE = 'titleWarningSave',
    VIEW_MODE = 'viewMode',
    CUSTOM_DISPLAY = 'customDisplay',
    MAX_DATE_CUSTOM ='maxDateCustom',
    MIN_DATE_CUSTOM ='minDateCustom',
    LIMIT_TODAY_DATE = 'limitTodayDate' 
}

export enum PropertyTypes {
    DATE = 'date',
    MONTH = 'month',
    DATE_TIME = 'datetime',
    TEXT_AREA = 'text-area',
    DROPDOWN = 'dropdown',
    CURRENCY = 'currency',
    NUMBER = 'number',
    DECIMAL = 'decimal',
    TEXT = 'text',
    FILE = 'file',
    MANY_TO_ONE = 'ManyToOne',
    MANY_TO_MANY = 'ManyToMany',
    MANY_TO_MANY_JSON = 'ManyToManyJson',
    ONE_TO_MANY = 'OneToMany',
    ONE_TO_ONE = 'OneToOne',
    AUTOLIST = 'autolist',
    CHECKBOX = 'checkbox',
    EDITOR = 'editor',
    SLIDE = 'slide',
    PASSWORD = 'password',
    CELLPHONE = 'cellphone',
    CUSTOM_COMPONENT = 'CustomComponent',
}

export enum TypesColumn {
    INTEGER = 'integer',
    FLOAT = 'float',
    STRING = 'string',
}

export enum ERRORS_FORM {
    REQUIRED = 'required',
    MIN_LENGTH = 'minlength',
    MAX_LENGTH = 'maxlength',
    MAX_CONTENT_SIZE = 'maxContentSize',
    PATTERN = 'pattern',
    ACCEPT = 'accept',
    MIN = 'min',
    MAX = 'max',
}
export enum FORM_EVENT_TYPES {
    PRE_LOAD = 'preLoad',
    PRE_PERSIST = 'prePersist'
}
export enum ENUM_ACTIONS {
    CREATE = 'create',
    EDIT = 'update',
    VIEW = 'view',
    DELETE = 'delete',
    SELECT = 'select',
    LIST = 'list',
}

export enum TypeFilters {
    RANGE = 'rangeFilter',
    SEARCH = 'searchFilter',
    ORDER = 'orderFilter',
    NUMERIC = 'numericFilter',
    DATE = 'dateFilter',
    DISTINCT = 'filterDistinc',
    CENTER_COST = 'filterByCencos',
    PERIOD = 'periodFilter',
    EXISTS_FILTER = 'existsFilter',
    NOT_FILTER = 'notFilter',
    ACTION_FILTER = 'customActionFilter',
    BOOLEAN_FILTER = 'booleanFilter',
    USER_PROPERTY_FILTER = 'userPropertyFilter'
}

export interface Filter {
    filterClass: string;
    properties?: any;
    propertyTypes?: any;
}

export interface Filters {
    rangeFilter?: Filter;
    searchFilter?: Filter;
    orderFilter?: Filter;
    existsFilter?: Filter;
    numericFilter?: Filter;
    dateFilter?: Filter;
    filterDistinc?: Filter;
}

export interface Table {
    name: string;
    schema?: string;
    indexes?: string;
    uniqueConstraints?: [];
    options: any;
}

export interface Validation {
    type: ValidationType;
    message?: string;
    maxMessage?: string;
    minMessage?: string;
    exactMessage?: string;
    charsetMessage?: string;
    allowNull?: boolean;
    max?: number;
    min?: number;
    payload?: string;
    groups?: string[];
    htmlPattern?: string;
    match?: boolean;
}

export interface Property {
    fieldName: string;
    column?: Column;
    type: string;
    formType?: PropertyTypes;
    label?: string;
    query: string[];
    display: any[];
    group: string;
    displayGroup?: string;
    separators: string[];
    customFormatDisplay: string[];
    options: any;
    validations: Validation[];
    filterCenter?: boolean;
    warningEmptyValue?: string;
    warningValue?: boolean;
    hasFilter?: boolean;
    nameFilter?: string;
    typeFilter?: TypeFilters;
    helpText?: string;
}

export interface Column {
    name: string;
    type: string;
    scale: number;
    length?: number;
    unique: boolean;
    nullable: boolean;
    precision: number;
    entity?: string;
    module?: string;
    fieldName?: string;
    mappedBy?: string;
}
export interface Event {
    field: string;
    type: FORM_EVENT_TYPES;
    options?: any;
}
export interface Entity {
    name: string;
    entity: string;
    table?: Table;
    events?: Event[];
    filters?: Filters;
    singular: string;
    plural: string;
    properties: Property[];
    alerts: Alert[];
    entityProperty?: EntityProperty;
    actions?: Action[];
    path?: string;
}

export interface Alert {
    message: string;
    values: string[];
    function: string;
    type: TypeAlert;
    onlyDisplay?: ValidationColumn[];
    formats: string;
    groups: string[];
}

export interface Action {
    location: LocationAction;
    type: TypeAction;
    icon?: string;
    text?: string;
    action: string;
    middleware?: Middleware;
    properties?: any;
    middlewareProperties?: any;
    canDisplay?: string;
}

export interface ValidationColumn {
    item: number;
    value: any;
    same?: any;
    noSame?: any;
    less?: any;
    higher?: any;
}

export enum TypeValueAlert {
    CENCOS = 'cencos'
}

export enum TypeAlert {
    WARNING = 'warning',
    ERROR = 'error',
    INFO = 'info'
}

export enum LocationAction {
    GENERAL = 'general',
    SPECIFIC = 'specific',
    FOOTER = 'footer',
    DETAIL_ITEM = 'detail',
}

export enum TypeAction {
    BACKEND = 'backend',
    UI = 'ui',
    LOAD_DATA = 'loadData',
    GENERATE_FILE = 'generateFile',
    DOWNLOAD_FILE = 'downloadFile',
    REDIRECT = 'redirect',
}

export enum Middleware {
    SELECT_MONTH = 'SelectMonth',
    SELECT_DATE = 'SelectDate',
    SELECT_DATE_LAST_YEAR= 'SelectDateLastYear',
    SELECT_RANGE_DATES = 'SelectRangeDates',
    CONFIRM_DIALOG = 'confirm'
}

export interface DataEntity {
    entityName?: string;
    multiSelect?: any[];
    module?: string;
    group?: string;
    item?: string;
    action?: ENUM_ACTIONS;
    initialData?: any;
    customData?: any;
    customTitle?: string;
    customActionBtnText?: string;
    filterCenter?: boolean;
    returnData?: boolean;
    keepDataObjects?: boolean;
    returnDataAfterSave?: boolean;
    customFilters?: { column: string, value: any, option?: any, filter?: TypeFilters} [];
    defaultFilters?: { column: string, value: any, option?: any, filter?: TypeFilters} [];
    addFilterItem?: any;
    /**
     * selectFilters -> object with name of column key and value as array filter like this:
     * any_column: { column: string, value: any, option?: any, filter?: string } [] \
     */
    selectFilters?: { };
    enableAdd?: boolean;
    disableEdit?: boolean;
    select?: boolean;
    displayBtnCancel?: boolean;
    displayBtnSave?: boolean;
    subtitleHTML?: string;
    customReturnAfterSave?: string[];
    editGroup?: string;
    displayGroup?: string;
    allowModifyFilter?: boolean;
    groupsOnAdd?: {group: string, name: string, customData?: any}[];
    groupsOnEdit?: {group: string, name: string, customData?: any}[];
    titlesForm?: any;
    rows?: any[];
    canEditAutoAssign?: any[];
    hiddenActionsTable?: boolean;
    exportableDocs?: boolean;
    groupDetail?: string;
    dialogMaxHeight?: number | string;
    dialogMaxWidth?: number | string;
    dialogMinWidth?: number | string;
    displayTitle?: boolean;
}

export interface EntityProperty {
     name?: string;
     entity?: string;
     permissions?: string[];
     module?: string;
     periodState?: PeriodState;
}

export const TICK = 1000;
