<div *ngIf="item">
    <mat-dialog-content>
        <mat-list>
            <ng-container *ngFor="let field of columns">
                <ng-container *ngIf="field.formType" [ngSwitch]="field.formType">
                    <ng-container *ngSwitchCase="TYPES.DATE">
                        <mat-list-item>
                            <div class="{{classLabel}}">
                                <p class="font-weight-bold">{{ field.label ? field.label : field.fieldName }}:</p>
                            </div>
                            <div class="{{classContent}}">
                                <p>{{ item[field.fieldName]  | date: 'dd-MM-yyyy'}}</p>
                            </div>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                    </ng-container>
                    <ng-container *ngSwitchCase="TYPES.TEXT_AREA">
                        <mat-list-item style="height: auto">
                            <div class="{{classLabel}}">
                                <p class="font-weight-bold">{{ field.label ? field.label : field.fieldName }}
                                    :</p>
                            </div>
                            <div class="{{classContent}}">
                                <p style="white-space: pre-wrap; word-break: break-word">{{ item[field.fieldName]  }}</p>
                            </div>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                    </ng-container>
                    <ng-container *ngSwitchCase="TYPES.NUMBER">
                        <mat-list-item>
                            <div class="{{classLabel}}">
                                <p class="font-weight-bold">{{ field.label ? field.label : field.fieldName }}:</p>
                            </div>
                            <div class="{{classContent}}">
                                <p>{{ item[field.fieldName]  }}</p>
                            </div>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                    </ng-container>
                    <ng-container *ngSwitchCase="TYPES.DECIMAL">
                        <mat-list-item>
                            <div class="{{classLabel}}">
                                <p class="font-weight-bold">{{ field.label ? field.label : field.fieldName }}:</p>
                            </div>
                            <div class="{{classContent}}">
                                <p>{{ item[field.fieldName]  }}</p>
                            </div>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                    </ng-container>
                    <ng-container *ngSwitchCase="TYPES.TEXT">
                        <mat-list-item>
                            <div class="{{classLabel}}">
                                <p class="font-weight-bold">{{ field.label ? field.label : field.fieldName }}:</p>
                            </div>
                            <div class="{{classContent}}">
                                <p>{{ item[field.fieldName]  }}</p>
                            </div>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                    </ng-container>
                    <ng-container *ngSwitchCase="TYPES.CURRENCY">
                        <mat-list-item>
                            <div class="{{classLabel}}">
                                <p class="font-weight-bold">{{ field.label ? field.label : field.fieldName }}:</p>
                            </div>
                            <div class="{{classContent}}">
                                <p>{{ item[field.fieldName] | currency }}</p>
                            </div>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                    </ng-container>
                    <ng-container *ngSwitchCase="TYPES.DROPDOWN">
                        <mat-list-item>
                            <div class="{{classLabel}}">
                                <p class="font-weight-bold">{{ field.label ? field.label : field.fieldName }}:</p>
                            </div>
                            <div class="{{classContent}}">
                                <p>{{getDataDropdown(field)}}</p>
                            </div>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                    </ng-container>
                    <ng-container *ngSwitchCase="TYPES.MANY_TO_ONE">
                        <mat-list-item>
                            <div class="{{classLabel}}">
                                <p class="font-weight-bold">{{ field.label ? field.label : field.fieldName }}:</p>
                            </div>
                            <div class="{{classContent}}">
                                <p>
                                    {{ getDataManyToOne(item, field) }}</p>
                            </div>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                    </ng-container>
                    <ng-container *ngSwitchCase="TYPES.ONE_TO_ONE">
                        <mat-list-item>
                            <div class="{{classLabel}}">
                                <p class="font-weight-bold">{{ field.label ? field.label : field.fieldName }}:</p>
                            </div>
                            <div class="{{classContent}}">
                                <p>
                                    {{ getDataManyToOne(item, field) }}
                                </p>
                            </div>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                    </ng-container>
                    <ng-container *ngSwitchCase="TYPES.ONE_TO_MANY">
                        <mat-list-item>
                            <div class="{{classLabel}}">
                                <p class="font-weight-bold">{{ field.label ? field.label : field.fieldName }}:</p>
                            </div>
                            <div class="{{classContent}} no-padding">
                                <ng-container *ngIf="getDataOneToMany(item, field); let list">
                                    <mat-list>
                                        <mat-list-item *ngFor="let item of list">
                                            <mat-icon
                                                    mat-list-icon>{{field.options[OPTION.ICON] ? field.options[OPTION.ICON] : 'double_arrow'}}</mat-icon>
                                            <div mat-line class="wrap-content" *ngFor="let p of field.display; let i = index">
                                                <mat-icon style="cursor: pointer" *ngIf="field.customFormatDisplay && field.customFormatDisplay[i]  ===  TYPES.FILE"
                                                          (click)="downloadMediaObject(item[p], field)"
                                                          mat-list-icon>arrow_downward</mat-icon>
                                                {{transformColumnValue(p, item, field, i)}} 
                                            </div>
                                            <div mat-line class="wrap-content" *ngIf="field.options[OPTION.CAN_EDIT] === undefined || field.options[OPTION.CAN_EDIT] === true">
                                                <mat-icon style="cursor: pointer" (click)="editRelation(item, field)"
                                                          mat-list-icon>edit</mat-icon>
                                            </div>
                                        </mat-list-item>
                                    </mat-list>
                                </ng-container>
                            </div>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                    </ng-container>
                    <ng-container *ngSwitchCase="TYPES.MANY_TO_MANY">
                        <ng-container *ngIf="getDataOneToMany(item, field); let list">
                            <mat-list-item *ngIf="list && list.length && list.length >= 1">
                                <div class="{{classLabel}}">
                                    <p class="font-weight-bold">{{ field.label ? field.label : field.fieldName }}:</p>
                                </div>
                                <div class="{{classContent}} no-padding">
                                        <mat-list>
                                            <mat-list-item *ngFor="let item of list">
                                                <mat-icon mat-list-icon>{{'speaker_notes'}}</mat-icon>
                                                <div mat-line class="wrap-content" *ngFor="let p of field.display; let i = index">
                                                    <mat-icon style="cursor: pointer" *ngIf="field.customFormatDisplay && field.customFormatDisplay[i]  ===  TYPES.FILE"
                                                            (click)="downloadMediaObject(item[p], field)"
                                                            mat-list-icon>arrow_downward</mat-icon>
                                                    {{transformColumnValue(p, item, field, i)}}
                                                </div>
                                            </mat-list-item>
                                        </mat-list>
                                </div>
                            </mat-list-item>
                        </ng-container>
                        <mat-divider></mat-divider>
                    </ng-container>
                    <ng-container *ngSwitchCase="TYPES.AUTOLIST">
                        <mat-list-item>
                            <div class="{{classLabel}}">
                                <p class="font-weight-bold">{{ field.label ? field.label : field.fieldName }}:</p>
                            </div>
                            <div class="{{classContent}}">
                                <p>
                                    {{ item[field.fieldName]  }}
                                </p>
                            </div>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                    </ng-container>
                    <ng-container *ngSwitchCase="TYPES.CELLPHONE">
                        <mat-list-item>
                            <div class="{{classLabel}}">
                                <p class="font-weight-bold">{{ field.label ? field.label : field.fieldName }}:</p>
                            </div>
                            <div class="{{classContent}}">
                                <ng-container *ngTemplateOutlet="memberTemplate; context:{o: getValueCellphone(item[field.fieldName]) }"></ng-container>
                                <ng-template #memberTemplate let-arr="o">
                                    <ng-template [ngIf]="arr">
                                        <p class="font-weight-bold d-inline">+{{arr[0]}}&nbsp;&nbsp;</p>
                                        <p class="d-inline">{{arr[1]}}</p>
                                        <p class="d-inline">{{arr[2]}}</p>
                                        <p class="d-inline">{{arr[3]}}</p>
                                    </ng-template>
                                </ng-template>
                            </div>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                    </ng-container>
                    <ng-container *ngSwitchCase="TYPES.FILE">
                        <mat-list-item>
                            <div class="{{classLabel}}">
                                <p class="font-weight-bold">{{ field.label ? field.label : field.fieldName }}:</p>
                            </div>
                            <div class="{{classContent}}">
                                <button mat-icon-button color="primary"
                                        *ngIf="!field.options[OPTION.MULTIPLE_FILE]  && item[field.fieldName]"
                                        matTooltip="Descargar" (click)="downloadMediaObject(item, field)">
                                    <mat-icon>arrow_downward</mat-icon>
                                </button>
                                <ng-container
                                        *ngIf="field.options[OPTION.MULTIPLE_FILE]  && item[field.fieldName]">
                                    <ng-container *ngIf="item[field.fieldName]; let list">
                                        <button mat-button [matMenuTriggerFor]="manyFiles">
                                            <mat-icon class="pl-1" color="primary" matSuffix>arrow_downward</mat-icon>
                                        </button>
                                        <mat-menu #manyFiles="matMenu" yPosition="above">
                                            <mat-list>
                                                <mat-list-item *ngFor="let file of list.split(','); let i = index;">
                                                    Anexo {{ i + 1 }}
                                                    <button mat-icon-button (click)="downloadMediaObject(item, field, i)">
                                                        <mat-icon>{{field.options[OPTION.ICON] ? field.options[OPTION.ICON] : 'arrow_downward'}}</mat-icon>
                                                    </button>
                                                    <div style="cursor: pointer" mat-line class="wrap-content"
                                                         (click)="downloadMediaObject(file, field)">{{item[field.display]}}</div>
                                                </mat-list-item>
                                            </mat-list>
                                        </mat-menu>
                                    </ng-container>
                                </ng-container>
                            </div>
                            <mat-divider></mat-divider>
                        </mat-list-item>
                    </ng-container>
                </ng-container>
            </ng-container>
        </mat-list>
    </mat-dialog-content>
    <mat-dialog-actions class="row justify-content-end mr-2">
        <button mat-raised-button mat-dialog-close color="primary">CERRAR</button>
    </mat-dialog-actions>
</div>
