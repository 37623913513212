import {Apollo, gql} from 'apollo-angular';
import {
    Component,
    ComponentFactoryResolver,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {
    DataEntity,
    Entity,
    ENUM_ACTIONS,
    ERRORS_FORM,
    Event,
    FORM_EVENT_TYPES,
    Options,
    Permission,
    Property,
    PropertyTypes,
    TypeFilters,
    TypesColumn,
    Validation,
    ValidationType
} from '@Interfaces/index';
import {AdfiRest} from '@services/adfi-rest';
import {AdfiService} from '@services/adfi-service';

import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AbstractControl, FormGroup, FormGroupDirective, ValidatorFn, Validators} from '@angular/forms';
import {SelectItemComponent} from '@Components/ui/select-item/select-item.component';

import {map, startWith} from 'rxjs/operators';
import {ConfirmDialogComponent, ConfirmDialogData} from '@Components/ui/confirm-dialog/confirm-dialog.component';
import {AdfiGraphqlService} from '@services/adfi-graphql.service';
import {LoadingService} from '@services/loading.service';
import {AdfiUtil} from '@Components/util/adfi-util';
import {AdfiGrowlService} from '@services/adfi-growl.service';
import {Filter} from '@Components/ui/util/Filter';
import {FormControl} from '@Components/ui/util/FormControl';
import {AcceptValidator, MaxSizeValidator, transformBytes} from '@Components/ui/util/Utilities';
import {isMoment} from 'moment/moment';
import {isDate} from 'moment';
import {MyTel} from '@Components/ui/tel-input/tel-input.component';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {
    AddUnspscAccountsDialogComponent
} from '@Components/dialogs/paa/add-unspsc-accounts-dialog/add-unspsc-accounts-dialog.component';

const textId = 'id';

@Component({
    selector: 'app-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

    OPTION = Options;
    TYPES = PropertyTypes;
    TYPE_COLUMN = TypesColumn;
    ENUM_ACTIONS = ENUM_ACTIONS;
    configTable: Entity;
    loadingData = true;
    columns: Property[];
    fieldsRequired: any = {};
    events: Event[];
    columnsQuery: Property[];
    displayColumns: string[];
    entityForm: FormGroup;
    currencies: Map<string, number>;
    autoList: any[] = [];
    relativeFiltersSubSelect = {};
    filteredOptions = {};
    loading: any = {};
    startValidDate: Date;
    endValidDate: Date;
    todayDate = new Date();
    id = '';
    actionName = '';
    hasPermissions = false;
    item: any;
    listValues = new Map<string, any[]>();
    listCustomValues = new Map<string, any[]>();
    loadingListValues = new Map<string, boolean>();
    base64Files: Map<string, any>;
    md5Files: Map<string, any>;
    @Output() preLoad = new EventEmitter<{ column: Property, validators: ValidatorFn[], control: FormControl }>();
    @Output() postSave = new EventEmitter<any>();
    @Output() preSave = new EventEmitter<any>();
    @Output() successInit = new EventEmitter<any>();
    @ViewChild('form') form;

    @Input('disableEdit') set disableEditInForm(value) {
        if (this.entityForm) {
            for (const f in this.entityForm.controls) {
                if (value) {
                    this.entityForm.controls[f].disable();
                } else {
                    this.entityForm.controls[f].enable();
                }
            }
        }
    }

    AdfiUtil = AdfiUtil;

    titleForm = '';
    displayTitle = true;
    customMinDateRange: Date;
    customMaxDateRange: Date;


    constructor(public dialogRef: MatDialogRef<FormComponent>,
                private adfiService: AdfiService,
                private apollo: Apollo,
                private adfiGraphql: AdfiGraphqlService,
                @Inject(MAT_DIALOG_DATA) public data: DataEntity,
                public dialog: MatDialog,
                public gaService: GoogleAnalyticsService,
                private loadingService: LoadingService, private adfiGrowl: AdfiGrowlService,
                private componentFactoryResolver: ComponentFactoryResolver) {
    }

    ngOnInit() {
        this.loadConfig();
        this.actionName = this.data.action === ENUM_ACTIONS.CREATE ? 'Agregar ' : this.data.action === ENUM_ACTIONS.EDIT ? 'Editar ' : '';
    }

    private loadConfig() {
        this.loadingService.show();
        this.data.action = this.data.action === ENUM_ACTIONS.EDIT && !this.data.item ? ENUM_ACTIONS.CREATE : this.data.action;
        const hiddenOption = this.data.action === ENUM_ACTIONS.CREATE ?
            Options.HIDDEN_ADD : Options.HIDDEN_EDIT;
        this.displayTitle = this.data.displayTitle !== undefined ? this.data.displayTitle : this.displayTitle;
        this.adfiService.post<Entity>(AdfiRest.getConfigData,
            {entity: this.data.entityName, module: this.data.module, formGroup: this.data.group, group: this.data.group})
            .subscribe((value: Entity) => {
                this.configTable = value;
                const action = this.data.action === ENUM_ACTIONS.CREATE ?
                    Permission.ADD : this.data.action === ENUM_ACTIONS.EDIT ? Permission.EDIT : '';
                const periodState = this.configTable.entityProperty ? this.configTable.entityProperty.periodState : undefined;
                this.hasPermissions = this.adfiService.validPermission(this.configTable.entity, action, periodState);
                this.columns = this.configTable.properties.filter(
                    prop => {
                        if (this.data.group) {
                            return this.data.group === prop.group && !prop.options[hiddenOption];
                        } else {
                            return !prop.group && !prop.options[hiddenOption];
                        }
                    }
                );
                this.displayColumns = this.columns.map((prop: Property) => prop.fieldName);
                if (this.data.addFilterItem) {
                    for (const keyItem of Object.keys(this.data.addFilterItem)) {
                        if (this.displayColumns.indexOf(keyItem) >= 0) {
                            const field = this.columns.find(value1 => (value1.fieldName === keyItem));
                            if (field.options[Options.FILTER_SELECT]) {
                                field.options[Options.FILTER_SELECT] =
                                    [...field.options[Options.FILTER_SELECT], ...this.data.addFilterItem[keyItem]];
                            } else {
                                field.options[Options.FILTER_SELECT] = this.data.addFilterItem[keyItem];
                            }
                        }
                    }
                }
                this.columnsQuery = this.columns;
                this.events = this.configTable.events;
                this.columnsQuery = [...this.columns, ...this.configTable.properties.filter(value1 => (value1.options[Options.FORCE_QUERY]))];
                this.loadDates();
                if (this.data.item) {
                    this.loadItem();
                } else {
                    this.configForm();
                    this.successInit.emit();
                }
            }, (e) =>  {
                this.adfiGrowl.errorMessage(e);
                this.loadingService.hide();
                this.dialogRef?.close();
            });
    }

    private configForm(row?: any) {
        this.entityForm = new FormGroup({});
        if (this.data.action === ENUM_ACTIONS.EDIT) {
            if (row !== null) {
                this.id = row.id;
            }
        }
        this.columns.forEach((value, index) => {
            const data = this.getDataItemForm(row, value);
            const validators = this.generateValidators(value);
            this.entityForm.addControl(value.fieldName, new FormControl(this.data && this.data.initialData ?
                this.data.initialData[value.fieldName] : (value['formType'] == PropertyTypes.SLIDE)? false: '', validators));
            if (this.data.initialData && this.data.initialData[value.fieldName]) {
                this.autoAssignValue(value, this.data.initialData[value.fieldName]);
            }
            const ev = this.events.find(v => v.field === value.fieldName);
            if (ev && ev.type === FORM_EVENT_TYPES.PRE_LOAD) {
                this.preLoad.emit({column: value, validators, control: this.entityForm.get(value.fieldName) as FormControl});
            }

            const okRelative = (list: any[]) => {
                this.listValues.set(value.fieldName, list);
                this.loadingListValues.set(value.fieldName, false);
                // this.entityForm.get(value.fieldName).enable();
                if (list.length === 1) {
                    this.entityForm.get(value.fieldName).setValue(list[0]);
                }
                if (data) {
                    this.entityForm.get(value.fieldName).setValue(list.find(value1 => value1.id === data.id));
                } else if (this.data && this.data.initialData && this.data.initialData[value.fieldName]) {
                    this.entityForm.get(value.fieldName).setValue(list.find(value1 => [value1.id, value1._id]
                        .includes(this.data.initialData[value.fieldName])));
                }
            };
            const errRelative = (e) => {
                this.loadingListValues.set(value.fieldName, false);
                this.adfiGrowl.errorMessage(e);
            };
            if (value.options[Options.MAT_SELECT] && value.options[Options.RELATIVE_FILTER] === undefined) {
                this.listValues.set(value.fieldName, []);
                this.loadingListValues.set(value.fieldName, true);
                this.getValuesRelation(value, okRelative, errRelative, this.entityForm.controls[value.fieldName].value);
            }
            if (value.options[Options.RELATIVE_FILTER]) {
                const listFilterRelative = value.options[Options.RELATIVE_FILTER];
                listFilterRelative.forEach((relativeVal) => {
                    this.entityForm.get(relativeVal.filter).valueChanges.subscribe(value1 => {
                        if (value1 !== undefined && value1 !== '' && value1 !== null &&
                            this.entityForm.get(value.fieldName).status !== 'DISABLED') {
                            this.addRelativeFilter(value, relativeVal, value1);
                            if (value.options[Options.MAT_SELECT]) {
                                this.getDataFilterRelative(value, relativeVal, value1, okRelative, errRelative);
                            }
                        } else if(value1 !== undefined && value1 !== '' && value1 !== null &&
                            this.entityForm.get(value.fieldName).status === 'DISABLED') {
                            this.addRelativeFilter(value, relativeVal, value1);
                        }else if (relativeVal.applySubfield && this.relativeFiltersSubSelect &&
                            this.relativeFiltersSubSelect[value.fieldName] &&
                            this.relativeFiltersSubSelect[value.fieldName][relativeVal.applySubfield]) {
                            this.relativeFiltersSubSelect[value.fieldName][relativeVal.applySubfield] =
                                this.relativeFiltersSubSelect[value.fieldName][relativeVal.applySubfield]
                                    .filter(valFil => (valFil.column !== relativeVal.column));
                        }
                    });
                });
            }
            if (this.findFilter(value.fieldName, TypeFilters.DISTINCT)) {
                this.autoList[value.fieldName] = [];
                this.loading[value.fieldName] = false;
            }
            const autoAssign = value.options[Options.AUTO_ASSIGN_VALUE];
            if (autoAssign) {
                if(autoAssign.hasOwnProperty(Options.VALUE_PROPERTY)){
                    this.addAutoAssignValueProperty(value, autoAssign, okRelative, errRelative);
                }else{
                    for (const clave in autoAssign) {
                        const subObjeto = autoAssign[clave];
                        this.addAutoAssignValueProperty(value, subObjeto, okRelative, errRelative);
                    }
                }
            }
            if (value.options[Options.MIN_DATE]) {
                this.entityForm.get(value.options[Options.MIN_DATE]).valueChanges.subscribe(() => {
                    this.entityForm.get(value.fieldName).setValue('');
                });
            }
            if (value.options[Options.MIN_DATE_CUSTOM]) {
                let parts: string[] = value.options[Options.MIN_DATE_CUSTOM].split("-");
                let month: number = parseInt(parts[1], 10);
                let day: number = parseInt(parts[2], 10);
                let year: number = parseInt(parts[0], 10)
                this.customMinDateRange = new Date(year,month-1,day);    
            }
            if (value.options[Options.MAX_DATE_CUSTOM]) {
                let parts: string[] = value.options[Options.MAX_DATE_CUSTOM].split("-");
                let month: number = parseInt(parts[1], 10);
                let day: number = parseInt(parts[2], 10);
                let year: number = parseInt(parts[0], 10)
                this.customMaxDateRange = new Date(year,month-1,day);
                if ((this.customMaxDateRange > this.todayDate)&& value.options[Options.LIMIT_TODAY_DATE]===true) {
                    this.customMaxDateRange =  this.todayDate;
                }
            }
            if (value.options[Options.RELATIVE_ENTITY]) {
                if (this.entityForm.get(value.options[Options.RELATIVE_ENTITY])) {
                    this.entityForm.get(value.options[Options.RELATIVE_ENTITY]).valueChanges.subscribe(value1 => {
                        const colOpt = this.columns.find(col => col.fieldName === value.options[Options.RELATIVE_ENTITY]);
                        if (colOpt) {
                            const option = colOpt.options.dropdown.find(opt => (opt && opt.key === value1));
                            if (option !== undefined && option.entity) {
                                if (!value.column) {
                                    value.column = {
                                        name: value.fieldName,
                                        nullable: false,
                                        precision: 0,
                                        scale: 0,
                                        type: '',
                                        unique: false,
                                        entity: option.entity, module: option.module
                                    };
                                }
                                value.column.entity = option.entity;
                                value.column.module = option.module;
                                value.options.itemsSave = option.itemsSave;
                                value.label = option.value ? option.value : 'Items';
                                value.display = option.display ? option.display : value.display;
                                value.separators = option.separators ? option.separators : value.separators;
                                value.customFormatDisplay = option.customFormatDisplay ?
                                    option.customFormatDisplay : value.customFormatDisplay;
                                value.options.dropdown = option.dropdown ? option.dropdown : value.options.dropdown;
                                if (!this.data.disableEdit) {
                                    this.entityForm.get(value.fieldName).enable();
                                }
                            } else {
                                this.entityForm.get(value.fieldName).disable();
                            }
                        }
                    });
                }
                this.entityForm.get(value.fieldName).disable();
            }
            if (this.data.disableEdit) {
                this.entityForm.controls[value.fieldName].disable({onlySelf: true, emitEvent: false});
            }
        });
        this.loadingData = false;
        this.loadingService.hide();
        this.titleForm = this.getTitleForm();
    }

    /**
     * Agregar la propiedad AutoAssignValue
     */
    addAutoAssignValueProperty(value, autoAssign,okRelative, errRelative){
        const indexDot = autoAssign[Options.VALUE_PROPERTY].indexOf('.');
        const nameValue = indexDot > 0 ? autoAssign[Options.VALUE_PROPERTY].substr(0, indexDot)
            : autoAssign[Options.VALUE_PROPERTY];
        if (this.entityForm.get(nameValue)) {
            this.entityForm.get(nameValue).valueChanges.subscribe(value1 => {
                if (value1 !== undefined) {
                    const valueData = this.extractInfield(value1, autoAssign[Options.VALUE_PROPERTY]);
                    if (autoAssign[Options.SAME] && autoAssign[Options.SAME].find(val => val === valueData) !== undefined) {
                        this.autoAssignValue(value, autoAssign[Options.ASSIGN]);
                    } else if (autoAssign[Options.NO_SAME] &&
                        autoAssign[Options.NO_SAME].find(val => val === valueData) === undefined) {
                            this.autoAssignValue(value, autoAssign[Options.ASSIGN]);
                    } else {
                        this.autoAssignValue(value, null);
                        if (!this.data.disableEdit) {
                            this.entityForm.get(value.fieldName).enable();
                        }
                        if (value.options[Options.RELATIVE_FILTER]) {
                            const listFilterRela = value.options[Options.RELATIVE_FILTER];
                            listFilterRela.forEach((relativeVal) => {
                                const valueFilter = relativeVal.value;
                                if (valueFilter !== null && valueFilter !== undefined && valueFilter !== '') {
                                    this.getDataFilterRelative(value, relativeVal, valueFilter, okRelative, errRelative);
                                }
                            });
                        }
                    }
                } else {
                    this.entityForm.get(value.fieldName).setValue(null);
                    if (!this.data.disableEdit) {
                        this.entityForm.get(value.fieldName).enable();
                    }
                }
            });
        }
    }

    /**
     * Agrega filtros relativos a la propiedad
     */
    addRelativeFilter(property: Property, relativeFilter, value) {
        let columnName = relativeFilter.column;
        let filter = [];
        let filterValue = AdfiUtil.extractSubfields(value, relativeFilter.value);
        if (isMoment(filterValue) || isDate(filterValue)) {
            filterValue = AdfiUtil.getUniversalDateString(filterValue);
        }
        if (relativeFilter.querySubfield !== undefined) {
            filter = [{
                column: columnName,
                value: {
                    [relativeFilter.querySubfield]: filterValue
                }
            }];
        } else {
            filter = [{
                column: columnName,
                value: filterValue
            }];
        }
        if (relativeFilter.customFilter) {
            filter[0].filter = relativeFilter.customFilter;
        }
        if (relativeFilter.extraFields) {
            columnName = relativeFilter.column + '.' + relativeFilter.extraFields;
            const otherValue = AdfiUtil.extractSubfields(value, relativeFilter.extraFields);
            filter.push({
                column: columnName,
                value: otherValue
            });
        }
        if (relativeFilter.applySubfield) {
            if (!this.relativeFiltersSubSelect[property.fieldName]) {
                this.relativeFiltersSubSelect[property.fieldName] = [];
            }
            if (this.relativeFiltersSubSelect[property.fieldName] &&
                this.relativeFiltersSubSelect[property.fieldName][relativeFilter.applySubfield]) {
                this.relativeFiltersSubSelect[property.fieldName][relativeFilter.applySubfield] =
                    [...this.relativeFiltersSubSelect[property.fieldName][relativeFilter.applySubfield]
                        .filter(valFil => (valFil.column !== columnName)), ...filter];
            } else {
                this.relativeFiltersSubSelect[property.fieldName][relativeFilter.applySubfield] = filter;
            }
        } else {
            if (property.options[Options.FILTER_IN_PROP]) {
                property.options[Options.FILTER_IN_PROP] = [...property.options[Options.FILTER_IN_PROP], ...filter];
            } else {
                property.options[Options.FILTER_IN_PROP] = filter;
            }
            if (this.relativeFiltersSubSelect[property.fieldName]) {
                this.relativeFiltersSubSelect[property.fieldName][relativeFilter.applySubfield] =
                    [...this.relativeFiltersSubSelect[property.fieldName].filter(valFil => (valFil.column !== columnName)), ...filter];
            } else {
                this.relativeFiltersSubSelect[property.fieldName] = filter;
            }
        }
    }

    getDataFilterRelative(property: Property, relativeFilter, value, ok, err) {
        this.listValues.set(property.fieldName, []);
        this.loadingListValues.set(property.fieldName, true);
        this.getValuesRelation(property, ok, err, value);
    }

    autoAssignValue(value: Property, assign) {
        if (!(this.data && this.data.canEditAutoAssign && this.data.canEditAutoAssign.indexOf(value.fieldName) > -1)) {
            this.entityForm.get(value.fieldName).disable();
        }
        this.entityForm.get(value.fieldName).setValue(assign);
        if (value.formType === PropertyTypes.CURRENCY) {
            this.fireCurrency(value.fieldName, assign);
        }
    }

    showField(fieldName: string): boolean {
        const control = this.entityForm.get(fieldName);
        return control && !control.disabled;
    }
    

    isRequiredConfig(prop: Property) {
        return (prop.validations && prop.validations.find(
                (value: Validation) => value.type === ValidationType.NOT_NULL || value.type === ValidationType.NOT_BLANK)) ||
            (prop.column && !prop.column.nullable) || (prop.options && prop.options[Options.NULLABLE] === false);
    }


    private getDataItemForm(row, value) {
        let data = row ? row[value.fieldName] : '';
        if (data && value.formType === this.TYPES.MANY_TO_ONE) {
            this.toStringObject(data, value);
        }
        if (data && value.formType === this.TYPES.FILE) {
            data = undefined;
        }
        if (value.formType === this.TYPES.MANY_TO_MANY || value.formType === this.TYPES.ONE_TO_MANY) {
            if (data && data.edges) {
                data = data.edges.map(d => {
                    this.toStringObject(d.node, value);
                    return d.node;
                });
            } else {
                data = [];
            }
        }
        if (data === '' && value.formType === this.TYPES.SLIDE) {
            data = false;
        }
        if (data && value.formType === this.TYPES.CURRENCY) {
            this.fireCurrency(value.fieldName, data);
        }
        if (data && value.formType === this.TYPES.CELLPHONE) {
            const [area, isp, exchange, subscriber] = data ? data.split(',') : [undefined, null, null, null];
            data = area ? new MyTel(area, isp, exchange, subscriber) : undefined;
        }
        return data;
    }


    private generateValidators(prop: Property) {
        const validators: ValidatorFn[] = [];
        // Option de configuración si otro parametro es requerido o no
        const noRequired = prop.options[Options.NO_REQUIRED_VALUE];
        const validationLength = prop.validations ?
            prop.validations.find((value: Validation) => value.type === ValidationType.LENGTH) : undefined;
        if (prop.formType === PropertyTypes.FILE && prop.options[Options.FILE_TYPE]) {
            validators.push(AcceptValidator(prop.options[Options.FILE_TYPE]));
        }
        if (validationLength) {
            if (prop.formType === PropertyTypes.FILE) {
                validators.push(MaxSizeValidator(validationLength.max));
            } else {
                validators.push(Validators.maxLength(validationLength.max));
                validators.push(Validators.minLength(validationLength.min));
            }
        } else if (prop.column && prop.column.length) {
            validators.push(Validators.maxLength(prop.column.length));
        }
        const validationRegex = prop.validations ?
            prop.validations.find((value: Validation) => value.type === ValidationType.REGEX) : undefined;
        if (validationRegex) {
            validators.push(Validators.pattern(validationRegex.htmlPattern));
        } else if (prop.column) {
            switch (prop.column.type) {
                case this.TYPE_COLUMN.INTEGER:
                    validators.push(Validators.pattern(/\d+/));
                    break;
                case this.TYPE_COLUMN.FLOAT:
                    validators.push(Validators.pattern(/\d+(,\d+)?$/));
                    break;
                case this.TYPE_COLUMN.STRING:
                    break;
            }
        }
        if (noRequired) {
            // Valida si el campo es requerido dependiendo de los valores de oytro campo
            this.entityForm.get(noRequired[Options.VALUE_PROPERTY]).valueChanges.subscribe(value => {
                if (noRequired[Options.SAME].find(val => val === value)) {
                    this.entityForm.get(prop.fieldName).clearValidators();
                    this.entityForm.get(prop.fieldName).updateValueAndValidity();
                } else {
                    validators.push(Validators.required);
                    this.entityForm.get(prop.fieldName).setValidators(validators);
                    this.entityForm.get(prop.fieldName).updateValueAndValidity();
                }
            });
        } else if (this.isRequiredConfig(prop)) {
            this.fieldsRequired[prop.fieldName] = true;
            validators.push(Validators.required);
        }
        const validationRange = prop.validations ?
            prop.validations.find((value: Validation) => value.type === ValidationType.RANGE) : undefined;
        if (validationRange) {
            validators.push(Validators.min(validationRange.min));
            validators.push(Validators.max(validationRange.max));
        }
        return validators;
    }

    private addFilterAngular(fieldName: string) {
        this.filteredOptions[fieldName] = this.entityForm.get(fieldName).valueChanges
            .pipe(
                startWith(''),
                map(val => this._filter(fieldName, val))
            );
    }

    private _filter(fieldName: string, value: string): string[] {
        const filterValue = value ? value.toLowerCase() : '';
        return this.autoList[fieldName] ? this.autoList[fieldName].filter(option => option.toLowerCase().includes(filterValue)) : [];
    }

    selectItem(data: DataEntity, field: Property) {
        data.enableAdd = field.options[Options.CAN_ADD];
        const filterSelect = field && field.options ? field.options[Options.FILTER_SELECT] : undefined;
        const relativeFilterSelect = field && field.options ? field.options[Options.FILTER] : undefined;
        if (field.options[Options.RELATIVE_FILTER]) {
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < field.options[Options.RELATIVE_FILTER].length; i++) {
                let haveFilter;
                const valFil = field.options[Options.RELATIVE_FILTER][i];
                if (relativeFilterSelect) {
                    haveFilter = relativeFilterSelect.find(datFil => (datFil.column === valFil.column));
                }
                if (haveFilter === undefined) {
                    const labelRelativeField = this.columns.find(value => (value.fieldName === valFil.filter));
                    this.adfiGrowl.warning('Alerta', 'Debe seleccionar ' + labelRelativeField.label);
                    return;
                }
            }
        }
        if (filterSelect) {
            data.defaultFilters = filterSelect;
        }
        if (relativeFilterSelect && Array.isArray(relativeFilterSelect)) {
            data.defaultFilters = [...(data.defaultFilters ? data.defaultFilters : []), ...relativeFilterSelect];
        }
        if (field.options[Options.GROUP_LIST]) {
            data.displayGroup = field.options[Options.GROUP_LIST];
        }
        const filterSelectEntity = this.data && this.data.selectFilters ? this.data.selectFilters[field.fieldName] : undefined;
        if (filterSelectEntity) {
            data.defaultFilters = [...data.defaultFilters, ...filterSelectEntity];
        }
        let dialogRef: MatDialogRef<any>;
        const afterClose = (result, multiple?) => {
            if (result) {
                this.loadingService.show();
                const next = (r) => {
                    this.toStringObject(r, field);
                    this.entityForm.get(field.fieldName)
                        .setValue(multiple ? [...this.entityForm.get(field.fieldName).value, r] : r);
                    this.loadingService.hide();
                };
                const error = (e) => {
                    this.loadingService.hide();
                    this.adfiGrowl.errorMessage(e);
                };
                if (dialogRef.componentInstance instanceof SelectItemComponent) {
                    this.adfiGraphql.getOneEntity(dialogRef.componentInstance.entity.singular,
                        field.query.join(' '), result.id, undefined, {next, error});
                } else if (dialogRef.componentInstance instanceof FormComponent) {
                    this.adfiGraphql.getOneEntity(dialogRef.componentInstance.configTable.singular,
                        field.query.join(' '), result.id, undefined, {next, error});
                } else {
                    next(result);
                }
            }
        };
        if (field.options[Options.ENABLED_ADD]) {
            data = {...data, returnData: true, action: ENUM_ACTIONS.CREATE};
            if (field.options[Options.GROUP_ADD]) {
                data.group = field.options[Options.GROUP_ADD];
            }
            if (this.relativeFiltersSubSelect[field.fieldName]) {
                data.addFilterItem = this.relativeFiltersSubSelect[field.fieldName];
            }
            dialogRef = this.dialog.open(FormComponent, {
                data,
                width: '80%',
                maxHeight: '70vh'
            });
            dialogRef.afterClosed().subscribe(result => {
                afterClose(result, true);
            });
        } else {
            dialogRef = this.dialog.open(SelectItemComponent, {
                data,
                width: '80%',
                maxHeight: '90vh'
            });
            dialogRef.afterClosed().subscribe(result => {
                afterClose(result);
            });
        }
    }

    extractDefaultOptions(data: DataEntity, field: Property) {
        const filterSelect = field ? field.options ? field.options[Options.FILTER_SELECT] : undefined : undefined;
        const canAdd = field ? field.options ? field.options[Options.ENABLED_ADD] : undefined : undefined;
        if (filterSelect) {
            data.defaultFilters = filterSelect;
        }
        data.defaultFilters = data.defaultFilters ? data.defaultFilters : [];
        const filterSelectEntity = this.data && this.data.selectFilters ? this.data.selectFilters[field.fieldName] : undefined;
        if (filterSelectEntity) {
            data.defaultFilters = [...data.defaultFilters, ...filterSelectEntity];
        }
        // TODO: Bug when no is defined options in map of field in backend ->
        //  options[Options.FILTER] is analyzed as function filter of native array
        if (field.options[Options.FILTER]) {
            data.defaultFilters = [...data.defaultFilters, ...field.options[Options.FILTER]];
        }
        if (canAdd) {
            data.enableAdd = canAdd;
        }
    }

    selectItems(data: DataEntity, field: Property) {
        let allFilters = field.options[Options.RELATIVE_FILTER] === undefined;
        if (field.options[Options.RELATIVE_FILTER] && this.relativeFiltersSubSelect[field.fieldName]) {
            allFilters = true;
            field.options[Options.RELATIVE_FILTER].forEach(valFil => {
                let haveFilter;
                if (valFil.applySubfield === undefined) {
                    haveFilter = this.relativeFiltersSubSelect[field.fieldName].find(datFil => (datFil.column === valFil.column));
                } else {
                    haveFilter = this.relativeFiltersSubSelect[field.fieldName][valFil.applySubfield]
                        .find(datFil => (datFil.column === valFil.column));
                }
                if (haveFilter === undefined) {
                    allFilters = false;
                    const labelRelativeField = this.columns.find(value => (value.fieldName === valFil.filter));
                    this.adfiGrowl.warning('Alerta', 'Debe seleccionar ' + labelRelativeField.label);
                }
            });
        }
        if (allFilters) {
            this.extractDefaultOptions(data, field);
            const defaultData = field ? field.options ? field.options[Options.DEFAULT_DATA] : undefined : undefined;
            if (defaultData && this.item) {
                data.customData = {};
                const column = 'column';
                const value = 'value';
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < defaultData.length; i++) {
                    data.customData[defaultData[i][column]] = this.item[defaultData[i][value]];
                }
            }
            if (field.options[Options.GROUP_LIST]) {
                data.displayGroup = field.options[Options.GROUP_LIST];
            }
            const customReturn = field ? field.options ? field.options[Options.CUSTOM_RETURN] : undefined : undefined;
            if (customReturn) {
                data.customReturnAfterSave = customReturn;
            }
            if (data.enableAdd && field.type !== PropertyTypes.MANY_TO_MANY) {
                data = {...data, returnData: !(this.item && this.item.id), action: ENUM_ACTIONS.CREATE};
                if (field.options[Options.GROUP_ADD]) {
                    data.group = field.options[Options.GROUP_ADD];
                }
                if (this.relativeFiltersSubSelect[field.fieldName]) {
                    data.addFilterItem = this.relativeFiltersSubSelect[field.fieldName];
                }
                const dialogRef = this.dialog.open(FormComponent, {
                    data,
                    width: '80%',
                    maxHeight: '70vh'
                });

                dialogRef.componentInstance.postSave.subscribe(result => {
                    if (result) {
                        this.toStringObject(result, field);
                        this.entityForm.get(field.fieldName).setValue([...this.entityForm.get(field.fieldName).value, result]);
                    }
                });
            } else {
                const dialogRef = this.dialog.open(SelectItemComponent, {
                    data,
                    width: '75%',
                    maxHeight: '70vh'
                });

                dialogRef.afterClosed().subscribe(result => {
                    if (result) {
                        this.toStringObject(result, field);
                        this.entityForm.get(field.fieldName).setValue([...this.entityForm.get(field.fieldName).value, result]);
                    }
                });
            }
        }
    }

    selectItemsManyToMany(data: DataEntity, field: Property) {
        if (field.options[Options.GROUP_LIST]) {
            data.displayGroup = field.options[Options.GROUP_LIST];
        }
        data.multiSelect = this.entityForm.get(field.fieldName).value;
        this.extractDefaultOptions(data, field);
        const dialogRef = this.dialog.open(SelectItemComponent, {
            data,
            width: '75%',
            maxHeight: '70vh'
        });
        dialogRef.afterClosed().subscribe((result: any[]) => {
            if (result && result.length) {
                result.forEach(r => this.toStringObject(r, field));
                this.entityForm.get(field.fieldName).setValue(result);
            }
        });
    }

    selectItemsManyToManyJson(data: DataEntity, field: Property) {
        data.multiSelect = this.entityForm.get(field.fieldName).value;
        this.extractDefaultOptions(data, field);
        let openModal = true;
        if (field.options[Options.RELATIVE_FILTER]) {
            field.options[Options.RELATIVE_FILTER].forEach(valFilter => {
                const val = this.entityForm.get(valFilter.filter).value;
                if (val) {
                    if (data.defaultFilters) {
                        data.defaultFilters = [...data.defaultFilters, {
                            column: valFilter.column,
                            value: val[valFilter.value]
                        }];
                    } else {
                        data.defaultFilters = [{
                            column: valFilter.column,
                            value: val[valFilter.value]
                        }];
                    }
                } else {
                    const relativeFieldLabel = this.columns.find(value => value.fieldName === valFilter.filter).label;
                    this.adfiGrowl.warning('Advertencia', `No ha seleccionado ${relativeFieldLabel}`);
                    openModal = false;
                }
            });
        }
        if (openModal) {
            const dialogRef = this.dialog.open(SelectItemComponent, {
                data,
                width: '75%',
                maxHeight: '70vh'
            });
            dialogRef.afterClosed().subscribe((result: any[]) => {
                if (result) {
                    result.forEach(r => {
                        if (field.options.itemsSave) {
                            const obj = {};
                            field.options.itemsSave.forEach(it => {
                                obj[it] = r[it];
                            });
                            r.toJSON = () => {
                                return JSON.stringify(obj);
                            };
                        }
                        this.toStringObject(r, field);
                    });
                    this.entityForm.get(field.fieldName).setValue(result);
                }
            });
        }
    }

    selectItemsCustomComponent(field: Property) {
        const comp = field.options[Options.CUSTOM_COMPONENT];
        let component = null;
        switch (comp) {
            case 'AddUnspscAccountsDialogComponent':
                component = AddUnspscAccountsDialogComponent;
                break;
        }
        this.componentFactoryResolver.resolveComponentFactory(component);
        const data = {};
        this.extractDefaultOptions(data, field);
        const dialogRef = this.dialog.open(component, {
            data,
            width: '75%',
            maxHeight: '80vh'
        });
        dialogRef.afterClosed().subscribe((result: any[]) => {
            if (result && result.length) {
                this.listCustomValues.set(field.fieldName, result);
            } else {
                this.listCustomValues.set(field.fieldName, null);
            }
        });
    }

    private toStringObject(obj: any, field: Property) {
        delete obj.toString;
        obj.toString = () => {
            return AdfiUtil.getDataColumn(obj, field);
        };
    }

    extractInfield(col, field) {
        let subfields = field.split('.');
        let data = col;
        if (subfields.length > 1 && data) {
            subfields = subfields.splice(1);
            for (const fie of subfields) {
                data = data[fie];
            }
            return data;
        } else {
            return data;
        }
    }

    save(form: FormGroupDirective) {
        if (!form.valid) {
            return;
        }
        let variables = {};
        const files = [];
        let customReturn = [];
        const afterSaveObj = [];
        for (const col of this.columns) {
            const val = form.control.get(col.fieldName).value;
            if ((val !== undefined && val !== null) || val === false ||
                (col && col.column && col.column.nullable && val === null) ||
                (col.formType === this.TYPES.FILE && col.options && col.options.multipleFile)) {
                if (col.options[Options.JSON_ENCODE]) {
                    variables[col.options[Options.JSON_ENCODE]] = JSON.stringify(val);
                } else {
                    switch (col.formType) {
                        case this.TYPES.FILE:
                            if (this.data.returnData || this.data.keepDataObjects) {
                                variables[col.fieldName] = {
                                    file: val,
                                    property: col
                                };
                            } else {
                                if (val) {
                                    variables[col.options.base64Field] = this.base64Files.get(col.options.base64Field);
                                    if (Array.isArray(this.md5Files.get(col.fieldName))) {
                                        variables[col.fieldName] = JSON.stringify(this.md5Files.get(col.fieldName));
                                    } else {
                                        variables[col.fieldName] = this.md5Files.get(col.fieldName);
                                    }
                                } else if (col.options && col.options.multipleFile) {
                                    variables[col.options.base64Field] = [];
                                }
                            }
                            break;
                        case this.TYPES.CURRENCY:
                            if (this.currencies && this.currencies.has(col.fieldName)) {
                                variables[col.fieldName] = this.currencies.get(col.fieldName);
                            } else {
                                variables[col.fieldName] = 0.0;
                            }
                            break;
                        case this.TYPES.MANY_TO_ONE:
                            if (this.data.returnData || this.data.keepDataObjects) {
                                variables[col.fieldName] = val;
                            } else {
                                variables[col.fieldName] = val ? val.id : val;
                            }
                            break;
                        case this.TYPES.ONE_TO_ONE:
                            if (this.data.returnData || this.data.keepDataObjects) {
                                variables[col.fieldName] = val;
                            } else {
                                if (col.options[Options.ENABLED_ADD] && val && val.length) {
                                    afterSaveObj.push({data: val, config: col, name: col.fieldName});
                                } else {
                                    variables[col.fieldName] = val ? val.id : val;
                                }
                            }
                            break;
                        case this.TYPES.MANY_TO_MANY:
                            if (this.data.returnData || this.data.keepDataObjects) {
                                variables[col.fieldName] = val;
                            } else {
                                variables[col.fieldName] = val ? val.map(item => (item.id)) : [];
                            }
                            break;
                        case this.TYPES.ONE_TO_MANY:
                            if (this.data.returnData || this.data.keepDataObjects) {
                                variables[col.fieldName] = val;
                            } else {
                                if (col.options[Options.ENABLED_ADD] && val && val.length && !(this.item && this.item.id)) {
                                    afterSaveObj.push({data: val, config: col, name: col.fieldName});
                                } else {
                                    variables[col.fieldName] = val.map(item => (item.id));
                                }
                            }
                            break;
                        case this.TYPES.DATE:
                        case this.TYPES.DATE_TIME:
                            variables[col.fieldName] = AdfiUtil.getUniversalDateString(val, col);
                            break;
                        case this.TYPES.MONTH:
                            variables[col.fieldName] = val.format('MM');
                            break;
                        case this.TYPES.MANY_TO_MANY_JSON:
                            if (val) {
                                const arr = val.map(item => (item.toJSON()));
                                variables[col.fieldName] = '[' + arr.join() + ']';
                            }
                            break;
                        case this.TYPES.CELLPHONE:
                            if (val) {
                                variables[col.fieldName] = val ? val.toJson() : undefined;
                            }
                            break;
                        case this.TYPES.CUSTOM_COMPONENT:
                            variables[col.fieldName] = JSON.stringify(this.listCustomValues.get(col.fieldName));
                            break;
                        default:
                            if (col.column) {
                                switch (col.column.type) {
                                    case this.TYPE_COLUMN.INTEGER:
                                        variables[col.fieldName] = parseInt(val, 10);
                                        break;
                                    case this.TYPE_COLUMN.FLOAT:
                                        variables[col.fieldName] = parseFloat(val);
                                        break;
                                    case this.TYPE_COLUMN.STRING:
                                        variables[col.fieldName] = val;
                                        break;
                                    default:
                                        variables[col.fieldName] = val;
                                        break;
                                }
                            } else {
                                variables[col.fieldName] = val;
                            }
                            break;
                    }
                }
            }
        }
        if (this.data.action === ENUM_ACTIONS.EDIT) {
            variables[textId] = this.id;
        }
        if (this.data.returnData) {
            const customData = this.data.customData;
            variables = {...variables, ...customData};
            this.postSave.emit(variables);
            this.dialogRef.close(variables);
        } else {
            const warnings = this.getWarningListSave(variables);
            const content = this.configTable.table && this.configTable.table && this.configTable.table.options && this.configTable.table.options[Options.TITLE_WARNING_SAVE] ? this.configTable.table.options[Options.TITLE_WARNING_SAVE] : undefined;
            const data: ConfirmDialogData = {
                title: '¿Desea guardar la información?',
                content,
                action: this.ENUM_ACTIONS.CREATE,
                warnings
            };
            const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                data
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result && typeof (result) !== 'object') {
                    this.loadingService.show();
                    const customData = this.data.customData;
                    variables = {...variables, ...customData};
                    this.preSave.emit(variables);
                    const next = (value) => {
                        if (!files.length && !afterSaveObj.length) {
                            this.loadingService.hide();
                            this.postSave.emit(value);
                            this.adfiGrowl.success('GUARDADO', 'El registro se ha agregado correctamente');
                            this.gaService.event('Guardar', 'ADFI', this.titleForm,
                                this.adfiService.user.id);
                            if ('close' in this.dialogRef) {
                                this.entityForm.reset();
                                this.dialogRef.close(true);
                            }
                        } else if (afterSaveObj.length || files.length) {
                            afterSaveObj.forEach(value1 => {
                                this.saveOneToManyObject(value1.config, value1.data, value.id, files.length > 0);
                            });
                            /* files.forEach(val1 => {
                                 this.saveFiles(val1, value);
                             });*/
                        } else {
                            this.entityForm.reset();
                            if (this.data.returnDataAfterSave) {
                                this.dialogRef.close(variables);
                            } else {
                                this.dialogRef.close(true);
                            }
                        }
                    };
                    const error = (e) => {
                        this.loadingService.hide();
                        this.adfiGrowl.errorMessage(e);
                    };
                    if (this.data.customReturnAfterSave) {
                        customReturn = [...customReturn, ...this.data.customReturnAfterSave];
                    }
                    if (this.configTable.table.options[Options.VIRTUAL]) {
                        this.adfiGraphql.mutationEntity('GenericEntity', {
                                entity: this.data.entityName,
                                module: this.data.module,
                                group: this.data.group,
                                data: JSON.stringify(variables)
                            }, customReturn.join(' '),
                            this.data.action, {next, error}, this.configTable.table.options
                                ? this.configTable.table.options[Options.NOT_ID] : false);
                    } else {
                        this.adfiGraphql.mutationEntity(this.configTable.entity, variables, customReturn.join(' '),
                            this.data.action, {next, error}, this.configTable.table.options
                                ? this.configTable.table.options[Options.NOT_ID] : false);
                    }
                } else if (result && typeof (result) === 'object') {
                    (this.entityForm.get(result.fieldName) as any).nativeElement.focus();
                }
            });
        }
    }

    /**
     * Genera el listado de advertencias que se desea mostrar
     */
    private getWarningListSave(variables: any) {
                const warnings = this.columns.filter(value => (value.warningEmptyValue &&
            !variables[value.fieldName]) || value.warningValue).map(value => ({
            warning: value.warningEmptyValue ? value.warningEmptyValue : this.getValueToWarning(variables[value.fieldName], value),
            fieldName: value.fieldName
        }));
        return warnings;
    }

    /**
     * retorna el valor seleccionado
     */
    private getValueToWarning(value: string | number | boolean, property: Property): string {
        let result = ""
        if (property['type']==="Column") {
            const column = this.columns.find(col => col.fieldName === property.fieldName);
            const labelAux = column ? column.label.toUpperCase() : '';
            result = value === null || value === "false" || value === false ? "NO " + labelAux : "SI " + labelAux;
        }else{
            result = AdfiUtil.getDataManyToOne(this.listValues.get(property.fieldName).find(item => (value === item.id)), property);
        }
        return result || 'Sin selección';
    }

    saveFiles(file: any, value: any) {
        this.loadingService.show();
        const data = {};
        const next = (r: any) => {
            const n = (pi) => {
                this.loadingService.hide();
                this.postSave.emit(pi);
                this.adfiGrowl.success('GUARDADO', 'El registro se ha agregado correctamente');
                this.entityForm.reset();
                this.dialogRef.close(true);
            };
            const e = (err) => {
                this.loadingService.hide();
                if (this.data.action === ENUM_ACTIONS.CREATE) {
                    this.adfiGraphql.deleteEntity(this.configTable.entity, value.id, undefined);
                }
                this.adfiGrowl.errorMessage(err);
            };
            data[file.col.fieldName] = r.name.join();
            this.adfiGraphql.updateEntity(this.configTable.entity, {
                    id: value.id,
                    ...data
                },
                'id ' + (this.data.customReturnAfterSave ? this.data.customReturnAfterSave.join(' ') : ''),
                {next: n, error: e});
        };
        const error = (e) => {
            this.loadingService.hide();
            this.adfiGrowl.errorMessage(e);
        };
        file.formData.append('dName', value[file.col.display[0]]);
        this.adfiService.uploadFile(`/api/uploadcloud/files/${file.col.options.bucket}/gcloud`,
            file.formData, undefined, true).subscribe(next, error);
    }


    searchNameVarByEntity(properties: Property[], entityName: string) {
        return properties.find((value: Property) => (value.column.entity === entityName));
    }

    /**
     * Guarda los datos de una variable que corresponde a una entidad y que
     * esta relacionada por id con la entidad principal que se acaba de crear
     * @param property las propiedades de la de la variable, contiene el nombre de la entidad y el modulo
     * @param obj el objecto de la entidad que se va ha insertar
     * @param idEntityName el id de la entidad principal que se va ha relacionar con las que se van a crear
     */
    saveOneToManyObject(property: Property, obj: any[], idEntityName: string, saveFiles: boolean) {
        this.loadingService.show();
        // TODO: Send correct group from properties
        this.adfiService.post<Entity>(AdfiRest.getConfigData,
            {entity: property.column.entity, module: property.column.module}).subscribe((config: Entity) => {
            const fieldNameId = this.searchNameVarByEntity(config.properties, this.data.entityName).fieldName;
            const custom = {};
            custom[fieldNameId] = idEntityName;
            obj.forEach(value => {
                if ((this.data.action === ENUM_ACTIONS.EDIT && value.__typename === undefined)
                    || this.data.action === ENUM_ACTIONS.CREATE) {
                    let customAttr = {};
                    if (this.data.customReturnAfterSave) {
                        customAttr = [...this.data.customReturnAfterSave];
                    }
                    const variables = {...custom, ...customAttr};
                    Object.keys(value).forEach((val: string) => {
                        if (value[val].__typename) {
                            variables[val] = value[val].id;
                        } else {
                            variables[val] = value[val];
                        }
                    });
                    this.apollo.mutate({
                        mutation: this.getMutation('create', config),
                        variables: {
                            input: variables
                        }
                    }).subscribe(value1 => {
                        if (!saveFiles) {
                            this.loadingService.hide();
                            this.postSave.emit(value1);
                            this.adfiGrowl.success('CORRECTO', 'El registro se ha agregado correctamente');
                            this.entityForm.reset();
                            this.dialogRef.close(true);
                        }
                    }, error => {
                        if (this.data.action === ENUM_ACTIONS.CREATE) {
                            this.adfiGraphql.deleteEntity(this.configTable.entity, value.id, undefined);
                        }
                        this.loadingService.hide();
                        this.adfiGrowl.error('Error', 'Error al agregar ' + config.name);
                    });
                }
            });
        });
    }


    findFilter(fieldName: string, type: TypeFilters) {
        let arr;
        if (this.configTable.filters[type] && Array.isArray(this.configTable.filters[type])) {
            arr = this.configTable.filters[type];
        } else if (this.configTable.filters[type]) {
            arr = Object.keys(this.configTable.filters[type]);
        }
        if (this.configTable.filters[type]) {
            const r = arr.filter(f => f.startsWith(fieldName));
            return r.length ? r : false;
        } else {
            return false;
        }
    }

    getAutoList(fieldName: string) {
        if (this.entityForm.get(fieldName) && this.entityForm.get(fieldName).value && this.entityForm.get(fieldName).value.length < 3) {
            this.autoList = [];
        }
        if (this.entityForm.get(fieldName) && this.entityForm.get(fieldName).value &&
            this.entityForm.get(fieldName).value.length % 3 === 0 &&
            (!this.autoList[fieldName] || this.autoList[fieldName].length >= 30 || this.autoList[fieldName].length === 0)) {
            let subs;
            this.loading[fieldName] = true;
            this.filteredOptions[fieldName] = this.apollo.query(
                {
                    query: this.getQueryAutoList(fieldName, this.entityForm.get(fieldName).value)
                }
            ).pipe(
                map((p) => p.data[this.configTable.plural].edges.map((node) => (node.node[fieldName])))
            );
            const ok = (value1) => {
                if (value1) {
                    this.autoList[fieldName] = value1;
                    this.addFilterAngular(fieldName);
                }
                this.loading[fieldName] = false;
            };
            const err = (e) => {
                subs.unsubscribe();
                this.loading[fieldName] = false;
            };
            subs = this.filteredOptions[fieldName].subscribe(ok, err);
        }
    }

    getQueryAutoList(fieldName: string, value: string) {
        return gql`query{
            ${this.configTable.plural}(distinc_${fieldName}:"${value}"){
        edges{
        node{
        ${fieldName}
        }
        }
        }
        }
        `;
    }

    getMutation(action, configTable) {
        return gql`mutation ${action}${configTable.singular.charAt(0).toUpperCase() + configTable.singular.slice(1)}($input: ${action }${configTable.singular.charAt(0).toUpperCase() + configTable.singular.slice(1)}Input!){
            ${action}${configTable.singular.charAt(0).toUpperCase() + configTable.singular.slice(1)}(input: $input) {
            ${configTable.singular}{
            id
        }
        }
        }`;
    }

    fireCurrency(name: string, event: number) {
        if (!this.currencies) {
            this.currencies = new Map<string, number>();
        }
        this.currencies.set(name, event);
    }

    getError(field: Property, form: AbstractControl) {
        if (form.hasError(ERRORS_FORM.REQUIRED)) {
            const validation = field.validations.find(value =>
                value.type === ValidationType.NOT_BLANK || value.type === ValidationType.NOT_NULL);
            return validation ? validation.message : 'Campo requerido';
        }
        if (form.hasError(ERRORS_FORM.PATTERN)) {
            const validationRegex = field.validations.find(value => value.type === ValidationType.REGEX);
            return validationRegex ? validationRegex.message : 'Campo no válido';
        }
        if (form.hasError(ERRORS_FORM.ACCEPT)) {
            return 'El formato del archivo no es compatible (' + form.getError(ERRORS_FORM.ACCEPT).currentFormat + ') Se requiere (' +
                field.options[Options.FILE_TYPE] + ')';
        }
        if (form.hasError(ERRORS_FORM.MAX_CONTENT_SIZE)) {
            const validationMax = field.validations.find(value => value.type === ValidationType.LENGTH);
            if (validationMax) {
                return `${validationMax.maxMessage} ${transformBytes(validationMax.max)} (${transformBytes(form.getError(ERRORS_FORM.MAX_CONTENT_SIZE).currentSize)})`;
            }
            return `El tamaño del archivo es invalido`;
        }
        if (form.hasError(ERRORS_FORM.MAX_LENGTH)) {
            const validationMax = field.validations.find(value => value.type === ValidationType.LENGTH);
            return validationMax ? validationMax.maxMessage.replace(/{{\s*limit\s*}}/g, form.errors.maxlength.requiredLength) : `El máximo de caracteres válidos es de ${form.errors.maxlength.requiredLength}`;
        }
        if (form.hasError(ERRORS_FORM.MIN_LENGTH)) {
            const validationMin = field.validations.find(value => value.type === ValidationType.LENGTH);
            return validationMin ? validationMin.minMessage.replace(/{{\s*limit\s*}}/g, form.errors.minlength.requiredLength) : `El mínimo de caracteres válidos es de ${form.errors.minlength.requiredLength}`;
        }
        if (form.hasError(ERRORS_FORM.MIN)) {
            const validationMin = field.validations.find(value => value.type === ValidationType.RANGE);
            return validationMin ? validationMin.minMessage.replace(/{{\s*limit\s*}}/g, form.errors.min.min) : `El valor mínimo válido es ${form.errors.min.min}`;
        }
        if (form.hasError(ERRORS_FORM.MAX)) {
            const validationMax = field.validations.find(value => value.type === ValidationType.RANGE);
            return validationMax ? validationMax.maxMessage.replace(/{{\s*limit\s*}}/g, form.errors.max.max) : `El valor máximo válido es ${form.errors.max.max}`;
        }
        return 'Campo no válido';
    }


    loadDates() {
        const next = (v: any) => {
            this.startValidDate = v.fechaApertura;
            this.endValidDate = v.fechaCierre;
            if (this.startValidDate.getMonth() === 0 && this.startValidDate.getDate() === 1) {
                this.startValidDate.setDate(this.startValidDate.getDate() + 1);
            }
        };
        const error = (e) => {
            this.adfiGrowl.errorMessage(e, 'Error al cargar la vigencia del usuario');
        };
        this.adfiService.getPeriodByUser({next, error});
    }

    loadItem() {
        const id = this.data.item;
        this.apollo.query({
            query: this.getQueryItem(id)
        }).subscribe(value => {
            if (value.data) {
                this.item = value.data[this.configTable.singular];
                this.configForm(this.item);
                this.columns.forEach((valueCol) => {
                    const data = this.getDataItemForm(this.item, valueCol);
                    this.entityForm.get(valueCol.fieldName).setValue(data);
                });
                this.successInit.emit();
            } else {
                this.adfiGrowl.error('Error inesperado del sistema', '');
                this.loadingService.hide();
            }
        });
    }

    private getQueryItem(id) {
        return gql`
            query{
                ${this.configTable.singular}(id:"${id}"){
                id
                ${AdfiUtil.getBasicFieldsGraphql(this.columnsQuery)}
                ${AdfiUtil.getFieldManyToOne(this.columnsQuery)}
                ${AdfiUtil.getFieldOneToMany(this.columnsQuery)}
                ${AdfiUtil.getFieldManyToMany(this.columnsQuery)}
                ${AdfiUtil.getFieldOneToOne(this.columnsQuery)}
            }
            }
        `;
    }

    removeItem(fieldName: string) {
        this.entityForm.get(fieldName).reset();
    }

    toUppercase(field: Property) {
        const text = this.entityForm.get(field.fieldName).value;
        if (field.options.upperCase) {
            this.entityForm.get(field.fieldName).setValue(text.toUpperCase());
        }
    }

    removeItems(fieldName: string, item: any) {
        this.entityForm.get(fieldName).setValue(this.entityForm.get(fieldName).value.filter(value => (value !== item)));
    }

    getButtonText(action: ENUM_ACTIONS) {
        switch (action) {
            case ENUM_ACTIONS.EDIT:
                return 'Actualizar';
        }
        return 'Agregar';
    }

    private getValuesRelation(field: Property, next, error, row, filter = []) {
        const f = new Filter(this.adfiService);
        f.entityName = field.column.entity;
        f.module = field.column.module;
        let filters = field.options[Options.FILTER_SELECT];
        filters = filters ? filters : [];
        const filterSelectEntity = this.data && this.data.selectFilters ? this.data.selectFilters[field.fieldName] : undefined;
        if (filterSelectEntity) {
            filters = [...filters, ...filterSelectEntity];
        }
        if (field.options[Options.FILTER_IN_PROP]) {
            filters = [...filters, ...field.options[Options.FILTER_IN_PROP]];
        }
        /*for (const fil of filters) {
            if (fil.isRelative) {
                fil.value = AdfiUtil.extractSubfields(row, fil.value);
            }
        }*/
        const withoutIds = field.options[Options.NOT_ID];
        if (filters && filter.length) {
            filters = [...filters, ...filter];
        } else if (!filters && filter.length) {
            filters = filter;
        }
        f.defaultQuery = filters;
        f.loadedConfig.subscribe(async () => {
            const params: any = f.generateFilters();
            const count = await this.adfiGraphql.countEntity(f.configTable.plural, params);
            this.adfiGraphql.getEntity(f.configTable.plural, field.query.join(' '), count, params, {next, error}, withoutIds);
        });
        f.loadConfig();
    }

    resetDate(fieldName: string) {
        this.entityForm.get(fieldName).setValue('');
    }

    getTitleForm() {
        let optionsGroup = null;
        if (this.data.action === ENUM_ACTIONS.CREATE && this.configTable.table.options &&
            this.configTable.table.options.groupsOnAdd !== undefined &&
            this.configTable.table.options.groupsOnAdd.length > 0) {
            optionsGroup = this.configTable.table.options.groupsOnAdd;
        }
        if (this.data.action === ENUM_ACTIONS.EDIT && this.configTable.table.options &&
            this.configTable.table.options.groupsOnEdit !== undefined &&
            this.configTable.table.options.groupsOnEdit.length > 0) {
            optionsGroup = this.configTable.table.options.groupsOnEdit;
        }
        if (optionsGroup) {
            const group = optionsGroup.find(value => this.data.group === value.group);
            if (group) {
                return this.actionName + group.name;
            }
        }
        return (this.data.action === ENUM_ACTIONS.EDIT && this.configTable.table.options.titleEditForm) ?
            this.configTable.table.options.titleEditForm.formatUnicorn(this.item) :
            (this.data.customTitle ? this.data.customTitle : this.actionName + this.configTable.name);
    }

    changeFile(field, $event) {
        if ($event) {
            const file = $event;
            if (Array.isArray(file)) {
                if (this.base64Files && this.base64Files.has(field.options.base64Field)) {
                    this.removeBase64Files(field.options.base64Field);
                    this.removeMd5Files(field.fieldName);
                }
                file.forEach(fileItem => {
                    const reader = new FileReader();
                    reader.readAsDataURL(fileItem);
                    reader.onload = () => {
                        this.fireBase64FilesMultiple(field.options.base64Field, reader.result);
                    };
                    AdfiUtil.computeChecksumMd5Hash(fileItem).then(
                        md5 => this.fireMd5FilesMultiple(field.fieldName, md5)
                    );
                });
            } else {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                
                reader.onload = () => {
                    this.fireBase64Files(field.options.base64Field, reader.result);
                };
                AdfiUtil.computeChecksumMd5Hash(file).then(
                    (md5) => {this.fireMd5Files(field.fieldName, md5)
                }
                    
                );
            }
        } else {
            this.removeBase64Files(field.options.base64Field);
            this.removeMd5Files(field.fieldName);
        }
    }

    fireBase64Files(name: string, base64: string | ArrayBuffer) {
        if (!this.base64Files) {
            this.base64Files = new Map<string, any>();
        }
        this.base64Files.set(name, base64);
    }

    fireBase64FilesMultiple(name: string, base64: string | ArrayBuffer) {
        if (!this.base64Files) {
            this.base64Files = new Map<string, any>();
        }
        let arr;
        if (this.base64Files.has(name)) {
            arr = new Array<any>(...this.base64Files.get(name));
        } else {
            arr = new Array<any>();
        }
        arr.push(base64);
        this.base64Files.set(name, arr);
    }

    fireMd5Files(name: string, checkSum) {
        if (!this.md5Files) {
            this.md5Files = new Map<string, any>();
        }
        this.md5Files.set(name, checkSum);
    }

    fireMd5FilesMultiple(name: string, checkSum) {
        if (!this.md5Files) {
            this.md5Files = new Map<string, any>();
        }
        let arr;
        if (this.md5Files.has(name)) {
            arr = new Array<any>(...this.md5Files.get(name));
        } else {
            arr = new Array<any>();
        }
        arr.push(checkSum);
        this.md5Files.set(name, arr);
    }

    removeBase64Files(name: string) {
        if (this.base64Files && this.base64Files.delete(name)) {
            this.base64Files.delete(name);
        }
    }

    removeMd5Files(name: string) {
        if (this.md5Files && this.md5Files.has(name)) {
            this.md5Files.delete(name);
        }
    }
}
