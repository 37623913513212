import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  Inject,
  Injector,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { MatDialog, MatDialogRef ,MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatStepper } from '@angular/material/stepper';
import {ENUM_ACTIONS, PeriodState, TICK} from '@Interfaces/index';
import {AdfiService} from '@services/adfi-service';
import {AdfiUtil} from '@Components/util/adfi-util';
import {AdfiGraphqlService} from '@services/adfi-graphql.service';
import {AdfiGrowlService} from '@services/adfi-growl.service';
import {LoadingService} from '@services/loading.service';
import {ConfirmDialogComponent, ConfirmDialogData} from '@Components/ui/confirm-dialog/confirm-dialog.component';
import {Subscription, timer} from 'rxjs';


@Component({
  selector: 'app-formulario-validacion-pi-paa',
  templateUrl: './formulario-validacion-pi-paa.component.html',
  styleUrls: ['./formulario-validacion-pi-paa.component.scss']
})
export class FormularioValidacionPiPaaComponent implements OnInit, AfterViewInit {


  constructor(private loadingService: LoadingService,
    private graphqlService: AdfiGraphqlService,
    private adfiGrowlService: AdfiGrowlService,
    private adfiService: AdfiService,
    private matDialog: MatDialog,) { }

  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('selectEquilibrio') haveEquilibrio: MatSelect;
  @ViewChild('selectFechaAprobacion') haveFechaAprov: MatSelect;
  @ViewChild('selectConsiderandos') haveConsiderandos: MatSelect;
  @ViewChild('selectDisposicionesGeneral') haveDisposicionesGeneral: MatSelect;
  @ViewChild('selectPaaA') havePaaA: MatSelect;
  @ViewChild('selectPaaB') havePaaB: MatSelect;
  @ViewChild('selectQuorum') haveQuorum: MatSelect;
  @ViewChild('selectAprobacion') haveAprobacion: MatSelect;
  @ViewChild('selectDocumento') haveDocumento: MatSelect;

  @ViewChild('selectCoherenciaConsiderandos') haveCoherenciaConsiderandos: MatSelect;
  @ViewChild('selectRepetConsiderandos') haveRepetConsiderandos: MatSelect;
  @ViewChild('selectNormaConsiderandos') haveNormaConsiderandos: MatSelect;
  @ViewChild('selectCoherenciaDisposicionesGeneral') haveCoherenciaDisposicionesGeneral: MatSelect;

  validationForm:any;
  period: number;
  fechaActual: { day: string, month: string, year: string };
  isEnabledButtonsAprove:boolean = false;
  isEnabledButtonsBack:boolean = false;
  isFinishForm:boolean = false;
  isCompletedForm:boolean = false;
  certificateExist:boolean = false;
  isAproved:boolean = false;
  canEditForm:boolean = true;
  countDown: Subscription;
  countStart: Subscription;
  isValidPeriod:boolean;
  indexStep = 0;
  counterEnd = 0;
  counterStart = 1;
  completedSteps = {
    selectEquilibrio: false,
    selectFechaAprobacion: false,
    selectConsiderandos: false,
    selectDisposicionesGeneral: false,
    selectPaaA: false,
    selectPaaB: false,
    selectQuorum: false,
    selectAprobacion: false,
    selectDocumento: false
  };

  private returnValidationForm = 'id _id validDeal validDealObs validFechaAprobacion fechaAprobacionObs validConsiderandos considerandosObs validCoherenteConsiderandos coherenteConsiderandosObs validPreestablecidosConsiderandos preestablecidosConsiderandosObs validNormatividadConsiderandos normatividadConsiderandosObs validDisposicionesGenerales disposicionesGeneralesObs validCoherenteDisposicionesGenerales coherenteDisposicionesGeneralesObs validPaaSeccionA paaSeccionAObs validPaaSeccionB paaSeccionBObs validQuorum quorumObs validAprobacion aprobacionObs validDocumentacion documentacionObs validForm certificate statusForm';

  ngOnInit(): void {
    const [day, month, year] = new Date().toLocaleDateString('es-ES', { year: 'numeric', month: 'long', day: 'numeric' }).split(' de ');
    this.fechaActual = {day:day, month: month, year: year}
    this.isValidPeriod = this.adfiService.getUserPeriod().estado !== PeriodState.CLOSE;
  }

  ngAfterViewInit() {
    this.loadingService.show();
    const next = (d) => {
        if (d) {
          if(this.adfiService.getUserPeriod().estado === PeriodState.CLOSE){
            this.period= this.adfiService.user.period;
          }else{
            this.period = d.period;
          }
            this.counterStart = this.adfiService.getCountDown(d.startDateRegister);
            if (this.counterStart > 0) {
                this.countStart = timer(0, TICK).subscribe(() => {
                    --this.counterStart;
                    if (this.counterStart <= 0) {
                        this.countStart.unsubscribe();
                        this.endTime(d);
                    }
                });
            } else {
                this.endTime(d);
            }
        } else if (this.canEditForm && this.isValidPeriod){
            const valid = (dates) => {
                if (dates.length) {
                    const param = JSON.parse(dates[0]);
                    this.counterStart = this.adfiService.getCountDown(param.startDate);
                    if (this.counterStart > 0) {
                        this.countStart = timer(0, TICK).subscribe(() => {
                            --this.counterStart;
                            if (this.counterStart <= 0) {
                                this.countStart.unsubscribe();
                                this.endTime(param);
                            }
                        });
                    } else {
                        this.endTime({endDateRegister: param.endDate});
                    }
                } else  {
                    this.counterStart = 0;
                    this.loadForm();
                }
            };
            const onErr = () => {
                this.counterStart = 0;
                this.loadForm();
            };
            this.graphqlService.loadAlerts('ExeptionAvalibleDate', 'getExceptionRegisterFor', `"3", "ValidationForm", "${this.adfiService.user.centroCosto._id}", "${new Date().getMonth() + 1}"`, valid, onErr);
        }
        this.loadingService.hide();
    };
    const error = (e) => {
        this.loadingService.hide();
        this.adfiGrowlService.errorMessage(e);
    };
    this.adfiService.getTimeControl('ValidationForm', next, error);
  }

  endTime(period) {
    this.counterEnd = this.adfiService.getCountDown(period.endDateRegister);
    this.countDown = timer(0, TICK).subscribe(() => --this.counterEnd);
    if (this.counterEnd > 0) {
      this.loadForm();
    } else {
        this.countDown.unsubscribe();
        this.loadingService.hide();
    }
  }

  validateForm(){
    switch (this.validationForm.statusForm) {
      case 'FND':
        this.blockForm();
        break;
      case 'APR':
        this.aproveForm();
        break;
      case 'DNG':
        this.deniedForm();
        break;
    }
  }

  blockForm(){
    this.isFinishForm = true;
    this.isEnabledButtonsAprove = true;
    this.canEditForm = false;
    this.indexStep = 9;
    this.blockSteps();
  }

  aproveForm(){
    this.loadingService.hide();
    this.isFinishForm = true;
    this.canEditForm = false;
    this.unlockSteps();
    this.isAproved = true;
  }

  deniedForm(){
    this.isFinishForm = true;
    this.isAproved = false;
    this.isEnabledButtonsBack = true;
    this.canEditForm = false;
    this.indexStep = 9;
  }

  blockSteps(){
    for (const key in this.completedSteps) {
      if (this.completedSteps.hasOwnProperty(key)) {
        this.completedSteps[key] = false;
      }
    }
  }

  unlockSteps(){
    for (const key in this.completedSteps) {
      if (this.completedSteps.hasOwnProperty(key)) {
        this.completedSteps[key] = true;
      }
    }
  }

  validateStep(data:any, obsKey:string){
    Object.keys(data).forEach((key) => {
      if (data[key] === undefined || data[key] === '') {
        data[key] = null;
      }
    });
    data.statusForm = 'PRC';
    this.saveStep(data);
    this.completedSteps[obsKey] = true; 
    this.isCompletedForm = obsKey === 'selectDocumento';
  }

  validateSubAnswersAfterTimeoutQuestionThree() {
    setTimeout(() => {
        this.validateSubAnswers({
            validConsiderandos: this.haveConsiderandos.value,
            considerandosObs: this.haveConsiderandos.value ? null : this.validationForm.considerandosObs,
            validCoherenteConsiderandos: this.haveCoherenciaConsiderandos.value,
            coherenteConsiderandosObs: this.haveCoherenciaConsiderandos.value ? null : this.validationForm.coherenteConsiderandosObs,
            validPreestablecidosConsiderandos: this.haveRepetConsiderandos.value,
            preestablecidosConsiderandosObs: this.haveRepetConsiderandos.value === false ? null : this.validationForm.preestablecidosConsiderandosObs,
            validNormatividadConsiderandos: this.haveNormaConsiderandos.value,
            normatividadConsiderandosObs: this.haveNormaConsiderandos.value === false ? null : this.validationForm.normatividadConsiderandosObs
        }, ['selectConsiderandos', 'selectCoherenciaConsiderandos', 'selectRepetConsiderandos', 'selectNormaConsiderandos']);
    }, 0);
  }

  isButtonDisabledQuestionThree(): boolean {
    return (
        !this.haveCoherenciaConsiderandos ||
        this.haveCoherenciaConsiderandos.value === null ||
        (this.haveCoherenciaConsiderandos.value === false && !this.validationForm.coherenteConsiderandosObs) ||
        !this.haveRepetConsiderandos ||
        this.haveRepetConsiderandos.value === null ||
        (this.haveRepetConsiderandos.value === true && !this.validationForm.preestablecidosConsiderandosObs) ||
        !this.haveNormaConsiderandos ||
        this.haveNormaConsiderandos.value === null ||
        (this.haveNormaConsiderandos.value === true && !this.validationForm.normatividadConsiderandosObs)
    );
  }

  validateSubAnswersAfterTimeoutQuestionFour() {
    setTimeout(() => {
        this.validateSubAnswers({
          validDisposicionesGenerales: this.haveDisposicionesGeneral.value, 
          disposicionesGeneralesObs: this.haveDisposicionesGeneral.value ? null : this.validationForm.disposicionesGeneralesObs,
          validCoherenteDisposicionesGenerales: this.haveCoherenciaDisposicionesGeneral.value, 
          coherenteDisposicionesGeneralesObs: this.haveCoherenciaDisposicionesGeneral.value ? null : this.validationForm.coherenteDisposicionesGeneralesObs,
          
        }, ['selectDisposicionesGeneral', 'selectCoherenciaDisposicionesGeneral']);
    }, 0);
  }

  isButtonDisabledQuestionFour(): boolean {
    return (
        !this.haveDisposicionesGeneral ||
        this.haveDisposicionesGeneral.value === null ||
        (this.haveDisposicionesGeneral.value === false && !this.validationForm.disposicionesGeneralesObs) ||
        !this.haveCoherenciaDisposicionesGeneral ||
        this.haveCoherenciaDisposicionesGeneral.value === null ||
        (this.haveCoherenciaDisposicionesGeneral.value === false && !this.validationForm.coherenteDisposicionesGeneralesObs)
    );
  }

  validateSubAnswers(data:any, obsKeys:any){
    Object.keys(data).forEach((key, i) => {
      if (data[key] === undefined || data[key] === '') {
        data[key] = null;
      }
    });
    data.statusForm = 'PRC';
    this.saveStep(data);
    this.completedSteps[obsKeys[0]] = true; 
  }

  saveStep(param: any, postSave: () => void = null) {
    this.loadingService.show();
    const next = (data) => {
        this.validationForm = data;
        setTimeout(() => {
          this.loadingService.hide();
          if(data.statusForm === 'INI' || data.statusForm === 'PRC'){
            this.stepper.next();
          }
        }, 1000);
        if (postSave) {
            postSave();
        }
    };
    const error = (e) => {
        this.adfiGrowlService.errorMessage(e);
        this.loadingService.hide();
    };
    this.graphqlService.mutationEntity('ValidationForm', {id:this.validationForm.id , ...param},
        this.returnValidationForm, ENUM_ACTIONS.EDIT, { next, error });
  }

  loadForm() {
    const next = (data) => {
        if (data) {
            this.validationForm = data;
            this.certificateExist = this.validationForm.certificate !== null;
            if(data.statusForm === 'PRC'){
              if (data.validDeal !== null) {
                this.completedSteps.selectEquilibrio = true;
                setTimeout(() => {
                  this.stepper.next();
                }, 1000);
              }
              if (data.validFechaAprobacion !== null) {
                this.completedSteps.selectFechaAprobacion = true;
                setTimeout(() => {
                  this.stepper.next();
                }, 1000);
              }
              if (data.validConsiderandos !== null) {
                this.completedSteps.selectConsiderandos = true;
                setTimeout(() => {
                  this.stepper.next();
                }, 1000);
              }
              if (data.validDisposicionesGenerales !== null) {
                this.completedSteps.selectDisposicionesGeneral = true;
                setTimeout(() => {
                  this.stepper.next();
                }, 1000);
              }
              if (data.validPaaSeccionA !== null) {
                this.completedSteps.selectPaaA = true;
                setTimeout(() => {
                  this.stepper.next();
                }, 1000);
              }
              if (data.validPaaSeccionB !== null) {
                this.completedSteps.selectPaaB = true;
                setTimeout(() => {
                  this.stepper.next();
                }, 1000);
              }
              if (data.validQuorum !== null) {
                this.completedSteps.selectQuorum = true;
                setTimeout(() => {
                  this.stepper.next();
                }, 1000);
              }
              if (data.validAprobacion !== null) {
                this.completedSteps.selectAprobacion = true;
                setTimeout(() => {
                  this.stepper.next();
                }, 1000);
              }
              if (data.validDocumentacion !== null) {
                this.completedSteps.selectDocumento = true;
                this.isCompletedForm = true;
                setTimeout(() => {
                  this.stepper.next();
                }, 1000);
              }
            }
            setTimeout(() => {this.validateForm();}, 1000);
        } else{
            this.graphqlService.createEntity('ValidationForm', null, this.returnValidationForm, { next, error });
        } 
    };
    const error = (e) => {
        this.adfiGrowlService.errorMessage(e);
    };
    this.graphqlService.getFirstEntity('validationForms',
        this.returnValidationForm,
        `cencosFilter_centroCosto: "", vigencia: ${this.period ? this.period : this.adfiService.user.period}`, {next, error});
  }

  sendToAprove(param: any, postSave: () => void = null) {
    this.loadingService.show();
    const next = (data) => {
        this.validationForm = data;
        setTimeout(() => {
            this.loadingService.hide();
            this.isFinishForm = true;
            this.canEditForm = false;
            this.isEnabledButtonsBack = false;
            this.adfiGrowlService.success('Formulario Validado','El formulario ha sido validado exitosamente');
            this.loadForm();
        }, 1000);
        if (postSave) {
            postSave();
        }
    };
    const error = (e) => {
        this.adfiGrowlService.errorMessage(e);
        this.loadingService.hide();
        this.isEnabledButtonsBack = true;
        this.isEnabledButtonsAprove = false;
        this.saveStep({statusForm : 'DNG'});
    };
    this.graphqlService.mutationEntity('ValidationForm', {id:this.validationForm.id , ...param},
        this.returnValidationForm, ENUM_ACTIONS.EDIT, { next, error });
  }

  sendToBack(param: any, postSave: () => void = null) {
    this.loadingService.show();
    const next = (data) => {
        this.validationForm = data;
        setTimeout(() => {
            this.loadingService.hide();
            this.isFinishForm = false;
            this.canEditForm = true;
            this.isCompletedForm = false;
            this.isEnabledButtonsBack = false;
            this.adfiGrowlService.success('Cambio estado', 'Actualizado correctamente a Pendiente');
            this.indexStep = 0;
            this.loadForm();
        }, 5000);
        if (postSave) {
            postSave();
        }
    };
    const error = (e) => {
        this.adfiGrowlService.errorMessage(e);
        // this.loadingService.hide();
        // this.saveStep({statusForm : 'INI'});
    };
    this.graphqlService.mutationEntity('ValidationForm', {id:this.validationForm.id , ...param},
        this.returnValidationForm, ENUM_ACTIONS.EDIT, { next, error });
  }

  closForm() {
    const dialog = this.matDialog.open(ConfirmDialogComponent, {data: {
      title: 'Finalizar diligenciamiento',
      content: `<p>¿Está seguro/a de finalizar el diligenciamiento?</p><p>Tenga en cuenta que una vez finalizado el diligenciamiento <span class="alert-color"><strong>no podrá modificar la información registrada</strong></span>, por lo que se recomienda validar los datos suministrados detalladamente`,
      action: ENUM_ACTIONS.DELETE
    } as ConfirmDialogData });
    dialog.beforeClosed().subscribe((ok) => {
      if (ok) {
        this.blockForm();
        this.saveStep({statusForm : 'FND'});
      }
    });
  }

  isStepCompleted(step: string): boolean {
    return this.completedSteps[step];
  }
  
  isStepEditable(step: string): boolean {
    switch (step) {
      case 'selectEquilibrio':
        return this.isFinishForm? (this.validationForm.statusForm === 'APR'? true : false):true; 
      case 'selectFechaAprobacion':
        return this.completedSteps.selectEquilibrio;
      case 'selectConsiderandos':
        return this.completedSteps.selectFechaAprobacion;
      case 'selectDisposicionesGeneral':
        return this.completedSteps.selectConsiderandos;
      case 'selectPaaA':
        return this.completedSteps.selectDisposicionesGeneral;
      case 'selectPaaB':
        return this.completedSteps.selectPaaA;
      case 'selectQuorum':
        return this.completedSteps.selectPaaB;
      case 'selectAprobacion':
        return this.completedSteps.selectQuorum;
      case 'selectDocumento' :
        return this.completedSteps.selectAprobacion;
      default:
          return false;
    }
  }

  generateReportAndSave() {
    this.adfiService.generateFile(
        'Presupuesto\\ValidationForm',
        JSON.stringify({}),
        'fileName',
        'application/pdf',
        'INFO-FORMULARIO-VALIDACION-PI-PAA-2024.pdf'
    ).subscribe(
        (pdfArrayBuffer) => {
          const pdfBlob = new Blob([pdfArrayBuffer], { type: 'application/pdf' });
            this.blobToBase64(pdfBlob, (base64Data) => {
                AdfiUtil.computeChecksumMd5Hash(pdfBlob).then((md5) => {
                    this.saveStep({ base64File: base64Data, certificate: md5 });
                });
            });
            setTimeout(() => {this.loadForm()}, 5000);
        },
        (e) => {
          this.adfiGrowlService.errorMessage(e);
        }
    );
  }

  blobToBase64(blob, callback: (base64Data: string) => void) {
    const reader = new FileReader();
    reader.onload = () => {
      const base64Data = reader.result as string;
      callback(base64Data);
    };
    reader.readAsDataURL(blob);
  }

  downloadFile() {
    AdfiUtil.downloadMediaObject(this.validationForm.certificate ,
        { fieldName: 'certificate', options: {bucket: 'files_end_period'}}, this.adfiService.user.period);
  }

}

@Component({
  selector: 'observation-dialog',
  templateUrl: 'observation-dialog.component.html',
})
export class ObservationDialogComponent {
  textInput: string = '';

  constructor(public dialogRef: MatDialogRef<ObservationDialogComponent>) {}

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onSaveClick(): void {
    this.dialogRef.close(this.textInput);
  }
}
